import { BrowserRouter } from "react-router-dom";
import Views from "./views";

import { Provider } from "urql";
import { client, ssrCache } from "./urqlClient";


import Web3Modal from "web3modal";
import {ethers} from "ethers";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
//import WalletConnectProvider from "@walletconnect/web3-provider";
import {useEffect, useState } from "react";





function App() {

  

  return (
    <div>
     
    
    <BrowserRouter>
    <Provider value={client}>
      <Views/>
    </Provider>
    </BrowserRouter>
    </div>
  );
}

export default App;
