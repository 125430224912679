import { useState } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

const data = [
    {
        question: 'Who is Bushman?',
        answer: 'Despite the ever-increasing recognition of his talent, Bushman remains a humble, down to earth figure. ',
        moreInfoLink: 'https://en.wikipedia.org/wiki/Bushman_(reggae_singer)',
        moreInfoText: 'See more info on Wikipedia'

    },
    {
        question: 'Can I buy Bushman NFTs here?',
        answer: 'Yes. ',
        moreInfoLink: 'mintSection',
        moreInfoText: 'Click here to Mint your Bushman NFTs.'
    },
    {
        question: 'What is the Mint price?',
        answer: 'Public: $100 per NFT',
        moreInfoLink: '',
        moreInfoText: ''
    },
    {
        question: 'When is the public launch date?',
        answer: 'Thursday, Sept. 15th, 2022',
        moreInfoLink: '',
        moreInfoText: ''
    },
    {
        question: 'How many NFTs can I Mint?',
        answer: 'You can mint up to 20 NFTs with your Ethereum wallet address or a custodial wallet via credit card.',
        moreInfoLink: 'mintSection',
        moreInfoText: ' Click here to Mint your Bushman NFTs.'
    },
    {
        question: 'How can I use the NFT?',
        answer: 'Please see the road map section of this web site ',
        moreInfoLink: '',
        moreInfoText: ''
    },
    {
        question: 'How will the money be used?',
        answer: 'Please see the road map section of this web site ',
        moreInfoLink: '',
        moreInfoText: ''
    },



]

const css = "mt-3  w-[350px] md:w-[500px] border-2 text-white border-green-400 rounded-sm";
const selectedCSS = "h-36 bg-black/50 " + css;
const notSelectedCSS = "h-12 bg-none " + css;

const Accordion = () => {
    const [selected, setSelected] = useState(null)

    const toggle = (i) => {

        if (selected === i) {
            return setSelected(null)
        }

        setSelected(i)
    }

    const handleClickToRemoteUrl = (remoteUrl) => {
        var url = remoteUrl;
        var win = window.open(url, '_blank');
        win.focus();

    }

    const handleClickNav = (sectionId) => {

        console.log("indexOf: ", sectionId.indexOf("http"))



        if (sectionId.indexOf("http") == 0) { //does "http" start at index 0
            handleClickToRemoteUrl(sectionId)
        }
        else {
            document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" })
        }


    }

    return (
        <div>

            {
                //const selectedContent = 'h-24 '


                data.map((item, i) => (


                    <div key={item.question} className={selected === i ? selectedCSS : notSelectedCSS}>
                        <div className='flex justify-between  gap-4 p-2 items-center cursor-pointer' onClick={() => toggle(i)}>

                            <div className='font-bold'>{item.question} </div>
                            {selected === i ? <BsChevronUp className="mr-2 hover:text-green-700  hover:opacity-90" size={15} />
                                :
                                <BsChevronDown className="mr-2 hover:text-green-700  hover:opacity-90" size={15} />}

                        </div>
                        {/* <div className={ selected == i ? 'visible p-3 transition-all' : 'hidden p-3 transition-all' }>{item.answer} 
                {item.moreInfoLink != '' ? '<a href="' + item.moreInfoLink + '"> See more info </a>' : ''}
                </div> */}

                        <div className={selected === i ? 'visible p-3 transition-all -mt-4' : 'hidden p-3 transition-all'}> {item.answer}

                            {item.moreInfoLink != '' &&

                                item.moreInfoLink.toLowerCase().includes == 'http' ?

                                <a href={item.moreInfoLink} rel="noreferrer" target="_blank" className="font-bold">
                                    <span className="underline underline-offset-1">{item.moreInfoText}</span></a>
                                :
                                <a rel="noreferrer" target="_blank" className="font-bold cursor-pointer"
                                    onClick={() => handleClickNav(item.moreInfoLink)}>
                                    <span className="underline underline-offset-1">{item.moreInfoText}</span></a>

                            }




                        </div>


                    </div>


                ))}
        </div>

    )


}

export default Accordion

//<BsChevronUp className="mr-2 hover:text-green-700  hover:opacity-60" size={21} onClick={() => updateAccordion({ id })}/> 