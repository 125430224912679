import { useEffect } from "react";

import { useNav } from "../hooks/useNav"
import { useScroll } from "../components/useScroll";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion"; //https://www.youtube.com/watch?v=-1ihhsv88O0&list=PLQKg8mIgoxKqTBBPf1cbVg20EceDUlcsm
import { videoAnimation, reasonsAnimation } from "../variants.tsx";
import Accordion from "../components/accordion";




 

const Faqs = () => {
  const faqsRef = useNav("FAQs")
  const [element, controls] = useScroll();

  const {ref, inView } = useInView({ threshold: 0.2});
  const animation = useAnimation();
  const animation2 = useAnimation();

  // const updateAccordion = async ({id}) => {
  //   console.log("updating accordion" + id);
  // };








  // useEffect (() => {

  //   if(inView) {

  //     animation.start({
  //       x: 0,
  //       transition: {
  //         type: 'spring', duration: 2, bounce: 0.3
  //       }
  //     });

  //     animation2.start({
  //       x: 0,
  //       transition: {
  //         type: 'spring', duration: 2, bounce: 0.3
  //       }
  //     });

  //   }

  //   if(!inView) {

  //     animation.start({x: '-100vw'});
  //     animation2.start({x: '100vw'});
  //   }

  //   //console.log("user effect hook, inView = ", inView)
    
  // }, [inView]);





  return (
    <section ref={faqsRef} id="faqsSection" className=" h-[600px] md:h-[600px] bg-gradient-to-b  from-[#749127] to-[#a8ca4a]">

       
       {/* <video  className="fixed h-[500px] z-0" muted loop autoPlay="autoPlay">
        <source type="video/mp4" src="/ganjarotation2.mp4"></source>
      </video>  */}
     

     <div  ref={ref} className="text-white">
     
      <div className="">
        <div className="mt-0 md:mt-24 sectionHeader">FAQs</div>
        <Accordion />
      </div>

     
     </div>

      
   


    </section>
  )
}

export default Faqs