import { useContext, useState, useEffect } from 'react';
import { NavContext } from '../context/NavContext';
import styles from '../styles/Header.module.css'
import { motion } from "framer-motion"; 
import { fadeIn, fadeInHorizontal} from "../variants.tsx";
import { SiDiscord, SiTwitter, SiInstagram} from 'react-icons/si';
import { GiHamburgerMenu} from 'react-icons/gi';
import { ModalHamburger } from "./modal-hamburger";
import { ModalMintWithCC } from "./modal-mint-with-cc";




const Header = () => {

  const [ header, setHeader] = useState(false);
  const { activeLinkId } = useContext(NavContext);
  const [ showModal, setShowModal] = useState(false);
  const [ modalMessage, setModalMessage] = useState(true)
  // const [ showModalMintWithCC, setShowModalMintWithCC] = useState(false);

  const [ isSafari, setIsSafari] = useState(false)

  //const navLinks = ["Welcome", "Artwork", "Mint", "Roadmap", "Team", "FAQs"];
  const navLinks = ["Artwork", "Mint", "Roadmap", "Team", "FAQs"];
  const handleClickLogo= () => {
    document.getElementById("welcomeSection").scrollIntoView({ behavior: "smooth" })
  }

  const handleClickToTrifecta = () => {
    var url = "https://trifecta.live/";
        var win = window.open(url, '_blank');
        win.focus(); //welcome
  }


  


  useEffect(  ()  =>  {
    // Safari 3.0+ "[object HTMLElementConstructor]" 
 //var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
 //console.log("isChrome: " + isChrome);
 

var sBrowser, sUsrAg = navigator.userAgent;

// The order matters here, and this may report false positives for unlisted browsers.

if (sUsrAg.indexOf("Firefox") > -1) {
  sBrowser = "Mozilla Firefox";
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
  sBrowser = "Samsung Internet";
  // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
  sBrowser = "Opera";
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
} else if (sUsrAg.indexOf("Trident") > -1) {
  sBrowser = "Microsoft Internet Explorer";
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
} else if (sUsrAg.indexOf("Edge") > -1) {
  sBrowser = "Microsoft Edge";
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
} else if (sUsrAg.indexOf("Chrome") > -1) {
  sBrowser = "Google Chrome or Chromium";
  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
} else if (sUsrAg.indexOf("Safari") > -1) {
  setIsSafari(true);
  sBrowser = "Apple Safari";
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
} else {
  sBrowser = "unknown";
}

//alert("You are using: " + sBrowser);

 
 })

 var isChromeBrowser = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

 //setIsChrome(isChromeBrowser);

 console.log("isChrome: " + isChromeBrowser);


  const renderNavLink = (content) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    const handleClickNav = () => {
      document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" })
    }

    const changeBackground = () => { 
      //console.log(window.scrollY);
      if (window.scrollY >= 80)
      {
        setHeader(true);
      } else {
        setHeader(false);   
      }

    }


   


    window.addEventListener('scroll', changeBackground);
     
   

    return (
      <motion.ul  className=""
      variants={fadeIn("down")}  
        initial="initial"
        animate="animate"
      key={content}>
        <li className={isSafari ? "w-24" : ""}>
          <button
            onClick={handleClickNav}
            className={activeLinkId === content ? styles.activeClass : ""}
          >{content}</button>
        </li>
      </motion.ul>
    )
  }
  
  return (

    /*
    background: none;
  color: #fff;
  padding: 1rem;
  position: fixed;
  width: 100%;
  */
    // <header className={header ? styles.headerActive : styles.header }>
    <header className={header ? 'headerActive'  : 'header' }>
      <div className={styles.container}>

      
      <ModalHamburger className="-ml-96 md:-ml-0" showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage} />
      {/* <ModalMintWithCC className="-ml-96 md:-ml-0" showModal={showModalMintWithCC} setShowModal={setShowModalMintWithCC} modalMessage={modalMessage} /> */}

        <div className="flex justify-between visible  md:invisible w-[350px]">
          <motion.img className="" variants={fadeIn("down")}  
            initial="initial"
            animate="animate" src={"/bushmansacramentlogo.png"} width={250} /> 

            <GiHamburgerMenu className="mr-2 hover:text-gray-100  hover:opacity-60" size={30}  onClick={() => { setShowModal(true) }} /> 
        </div>
        
        <div className={styles.navContainer}>
          <p className="text-lg  cursor-pointer md:text-4xl" onClick={handleClickLogo}>
            
          <motion.img variants={fadeIn("down")}  
        initial="initial"
        animate="animate"  
       
        className="invisible md:visible -mt-2" src={"/bushmansacramentlogo.png"} width={250} /> 
          </p>


          
          <div className="invisible  md:visible">
          <nav className="">

          <button onClick={(e) => {handleClickToTrifecta()} }>Trifecta Project</button>

            {navLinks.map(nav => renderNavLink(nav))}
            

           <motion.div 
            variants={fadeInHorizontal("left")}  
            initial="initial"
            animate="animate"
            className="flex space-x-2">

            <a target="_blank" href="http://twitter.com/nyahmanbushman?s=11&t=IQoL2-59AqT5pnJIyyUuug">
                <SiTwitter className="mr-2 hover:text-green-700  hover:opacity-60" size={21} /> 
            </a>
            <a target="_blank" href="https://instagram.com/original_bushman?igshid=YmMyMTA2M2Y=">
                <SiInstagram className="mr-2 hover:text-green-700  hover:opacity-60" size={21} /> 
            </a>

            <a target="_blank" href="https://discord.gg/AKyWrehkRj">
                <SiDiscord className="mr-2 hover:text-green-700  hover:opacity-60" size={21} /> 
            </a>
            
            </motion.div>

          </nav>
          
          </div>

         
         

        </div>
      </div>

      
    </header>
  )
}

export default Header