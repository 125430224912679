import { useEffect } from "react";

import { useNav } from "../hooks/useNav"
import { useScroll } from "../components/useScroll";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion"; //https://www.youtube.com/watch?v=-1ihhsv88O0&list=PLQKg8mIgoxKqTBBPf1cbVg20EceDUlcsm
import { videoAnimation, reasonsAnimation } from "../variants.tsx";
import Accordion from "../components/accordion";



 

const Roadmap = () => {
  const roadmapRef = useNav("Roadmap")
  const [element, controls] = useScroll();

  const {ref, inView } = useInView({ threshold: 0.2});
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();

  // const updateAccordion = async ({id}) => {
  //   console.log("updating accordion" + id);
  // };








  useEffect (() => {

    if(inView) {

      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.3
        }
      });

      animation2.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.3
        }
      });

      animation3.start({
        opacity: 1,
        transition: {
          type: 'spring', duration: 5, bounce: 0.3
        }
      });

    }

    if(!inView) {

      animation.start({x: '-100vw'});
      animation2.start({x: '100vw'});
      animation3.start({opacity: 0});
    }

    //console.log("user effect hook, inView = ", inView)
    
  }, [inView]);





  return (
    <section ref={roadmapRef} id="roadmapSection" className="h-[1250px] bg-gradient-to-b  from-[#222] to-[#000] px-4">

<div  ref={ref} className="text-white ">
     
      <motion.div animate={animation3} className="">
        <motion.div animate={animation3} className="sectionHeader text-center ">ROADMAP AND BENEFITS</motion.div>
       
        <div className="flex  items-top h-[1000px] mt-[100px] bg-no-repeat  lg:bg-[url('')] bg-[url('roadmaptemplate.png')]" >  
        
        
        <div className="hidden lg:inline-flex" >  

        <img className="object-none h-[610px]" src={"/roadmaptemplate.png"}  />

        </div>

        <div className="ml-0 lg:-ml-[15px] flex flex-col    items-center  lg:items-start gap-0 w-[740px] text-sm" >  

          <div className="mt-12 p-4   -translate-y-[120px] rounded-lg  w-[350px] bg-green-900/60">
            <div className="text-lg font-bold">PHASE I</div>
            {/* <div className="font-bold">Launch</div> */}
           
            <div className="flex flex-col gap-0">
              
              <div>- Launch Trifecta NFT Collection: </div>
              <div className="ml-4"> 
                <div>1000 Sacrament NFTs </div>
                <div>1000 Love NFTs</div>
                <div>1000 Conscious NFTs</div>
              </div>

              <div>- Website and Discord</div>

              

              <div>- Whitelisting Benefits on upcoming collections</div>
              <div>- Exclusive offers, discounts and events </div>
              <div className="ml-4"> 
                <div>for our community around the world.</div>
              </div>
               
            </div>
          </div>


          <div className="mt-12 p-4   -translate-y-[140px] rounded-lg  w-[450px] bg-green-900/60 resize">
            <div className=" text-lg  font-bold">PHASE II</div>
            {/* <div className="font-bold">Community Access & Connection</div> */}
            <div className="flex flex-col gap-0">
              
              <div>- All 3 New Bushman Albums </div>
              <div>- All 3 Trifecta Concerts Streamed Live in the Metaverse</div>
              <div className="ml-4"> 
                <div> </div>
              </div>
              <div>- Launch the Sound System Package for all Trifecta DubPlates </div>
              <div>- Free Tickets to the BushBash Festival in Jamaica </div>
              <div>- Exclusive Airdrop Rewards</div>
              <div className="ml-4"> 
                <div> </div>
              </div>

              <div>- Reward Growing Community Giveaways:</div>
              <div className="ml-4"> 
                <div>Free Trip to Jamaica to Hangout with Bushman for 1 lucky NFT holder (most rare NFT)</div>
              </div>
              
            </div>
          </div>


          <div className="mt-12 p-4   -translate-y-[150px] rounded-lg  w-[520px] bg-green-900/60">
            <div className=" text-lg  font-bold">PHASE III</div>
            {/* <div className="font-bold">Public Service</div> */}
            <div className="flex flex-col gap-0">
              
              <div>- Activate and fund charity wallet </div>
              <div className="ml-4"> 
                <div> (Several Charaties will be considered)</div>
              </div>
              <div>- Holders will be granted 20% of proceeds from selected Trifecta Concerts</div>
              <div>- Voting power on all major decisions related to Trifecta Project</div>
              <div>- Merch store will be released to the public (Limited Designs and Quantity)</div>
              <div className="ml-4"> 
                <div> </div>
              </div>
              

            </div>
          </div>







        </div>

        </div>
      </motion.div>

     
     </div>


    </section>
  )
}

export default Roadmap