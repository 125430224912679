import { useEffect, createContext, useState } from "react"
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/ethereum-provider";



export const Web3AccountContext = createContext()

const Web3AccountProvider = ({ children }) => {
  const [userAccount, setUserAccount] = useState("");
  const [web3Modal, setWeb3Modal] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      const web3modal = new Web3Modal({
        network: "mumbai", // optional
        cacheProvider: false, // optional
        providerOptions, // required
      });
      setWeb3Modal(web3modal);



      web3modal.on("accountsChanged", (accounts) => {
        console.log(accounts);
      });



    }
  }, []);

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        //infuraId: process.env.REACT_APP_INFURA_ID, // required
        infuraId: "65a16ffd9e7b4e0480430d6064b9901f",
        80001: "https://rpc-mumbai.matic.today",
      },
    },
  };


  const connectWeb3 = async () => {
    try {
      const instance = await web3Modal.connect();
      //console.log(instance);

      //console.log("test: " + instance.eth.getAccounts());
      //
      // console.log(instance)

      const provider = new ethers.providers.Web3Provider(instance);


      instance.on("accountsChanged", (accounts) => {
        console.log("myaccounts: " + accounts);
        setUserAccount(accounts)
      });

      // Subscribe to chainId change
      instance.on("chainChanged", (chainId) => {
        console.log(chainId);
      });

      // Subscribe to session disconnection
      instance.on("disconnect", (code, reason) => {
        console.log(code, reason);
      });



      //console.log(provider.getNetwork());
      //console.log(provider.provider._state.accounts);

      //let signer = await provider.getSigner();
      //let balance = await signer.getBalance();
      //console.log("balance: " + balance)

      const network = await provider._networkPromise;
      //console.log(network.name);
      //console.log(network.chainId);

      console.log(network);

      setUserAccount(instance.selectedAddress);
      //const accounts = await provider.listAccounts();

      //alert("account: " + accounts.current);
      //setUserAccount(accounts.current);
    } catch (err) {
      console.log(err);
    }
  };
  
  
  
  
  const providerValue = {
    userAccount,
    setUserAccount,
    connectWeb3
  }
  

  return (
    <Web3AccountContext.Provider value={providerValue}>
      {children}
    </Web3AccountContext.Provider>
  )
}

export default Web3AccountProvider