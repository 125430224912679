
import { Routes, Route } from "react-router-dom"; //Outlet

import Header from "./components/Header";
import HeaderListing from "./components/HeaderListing";
import FAQs from "./sections/FAQs";
import Roadmap from "./sections/Roadmap";
import Team from "./sections/Team";
import Home from "./sections/Home";
import Mint from "./sections/Mint";
import Artwork from "./sections/Artwork";
import Footer from "./sections/Footer";
import NavProvider from './context/NavContext'; 
import Web3AccountProvider from './context/Web3AccountContext'; 
import { AuthProvider } from "./hook/auth";

import Confirm from "./components/confirm";
import NotFound from "./components/not-found";
import Cancel from "./components/cancel";
import Success from "./components/success";
import ListTokens from "./components/list-tokens";


//Get multiple URL parameters using useParams() hook
//https://stackoverflow.com/questions/61285434/get-multiple-url-parameters-using-useparams-hook






const Views = () => {


   

    return (
        <Routes>
            <Route path="/" element={
                <AuthProvider>
                <NavProvider>
                    <Header />
                    <Home />
                    <Artwork />
                    <Mint />
                    <Roadmap />
                    <Team />
                    <FAQs />
                    <Footer />
                </NavProvider> 
                </AuthProvider>  
            } />

            {/* <Route path="/confirm"  element={ <div> <Outlet/> These are today's confirms </div> }>
                <Route path="1" index element={ <div> This confirm page 1 </div> } />
                <Route path="2" index element={ <div> This confirm page 2 </div> } />
            </Route> */}

            {/* <Route path="/confirm" element={ <div> These are today's confirms <Outlet/> </div> }> */}
            <Route path="/confirm">
                <Route path=":id" element={<Confirm/>} />
               
            </Route>

            <Route path="/cancel" index element={<Cancel/>} />

            
            {/* <Route path="/list-tokens" index element={
                <AuthProvider>
                    <NavProvider>
                    <Web3AccountProvider>
                        <HeaderListing />
                        <ListTokens/>
                    </Web3AccountProvider>
                    </NavProvider> 
                </AuthProvider>
            } /> */}

            <Route path="/list-tokens">
                <Route path=":id"  element={
                    <AuthProvider>
                        <NavProvider>
                        <Web3AccountProvider>
                            <HeaderListing />
                            <ListTokens/>
                        </Web3AccountProvider>
                        </NavProvider> 
                    </AuthProvider>
                } />
                
            </Route>


            
            {/* <Route path="/success" index element={<Success/>} /> */}

            <Route path="/success">
                <Route path=":id/:userId" element={<Success/>} />
            </Route>

            {/* <Route path="*" element={ <div> 404 Not Found </div> } /> */}
            <Route path="*" element={ <NotFound/> } />



        </Routes>
        
    )


}

export default Views

