import { useState, useEffect, useContext, createContext,  } from "react";
import AuthService from "../service/AuthService";

import {collection, onSnapshot, updateDoc, query, orderBy, doc, increment, getDoc} from "@firebase/firestore";
import { db } from "../config/firebase.config";
//import { getAuth, sendSignInLinkToEmail } from "firebase/auth";




// ... React Authentication with Firebase: React Hooks and Private Routes 
// ... https://www.youtube.com/watch?v=8Xnpipa2k2M
// ... useHooks.com

//import firebase from 'firebase/app'; 
//import 'firebase/auth';


const authContext = createContext();

export default function useAuth() {
    
    return useContext(authContext);
}

export function AuthProvider(props) {
//export const AuthProvider = ({children}) => {
 
    const [user, setUser] = useState('');
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState("");
    const [showModal, setShowModal ] = useState(false);
    const [modalMessage, setModalMessage ] = useState("");
    const [currentPage, setCurrentPage] = useState("/dashboard");
    //const [isAuthenticating, setIsAuthenticating] = useState(true);

    const loginWithGoogle = async () => {
        const { error, user } = await AuthService.loginWithGoogle();
        setUser(user ?? null);
        setError(error ?? "");
    };

    const loginWithiPing = async (email, password) => {
        const { error, user } = await AuthService.loginWithiPing(email, password);
        setUser(user ?? null);
        setError(error ?? "");
    };

    const forgotPassword = async (email) => {
        const { error, user } = await AuthService.forgotPassword(email);
        setUser(user ?? null);
        setError(error ?? "");

    }


    const getUserInfo = async (userId) => {

        onSnapshot(doc(db, "users", userId), (doc) => {
			//const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
			//console.log(source, " data: ", doc.data());
            setUserData(doc.data());
		});
    }  



    const makeUserWithEmailAndPassword = async (email, password) => {		
        
        
        
        //try {


            const { error, userx } = await AuthService.makeUserWithEmailAndPassword(email, "1234567");
            console.log("error: ", error );
            
            setUser(userx.uid);
            setError(error ?? "");

           

            //return { error: error }
           
            
       // } catch (error) {

       //     return { error: error }
            
        //}


        
        
        // console.log("error: ", error );
        // console.log(userx);

        //if (userx) {
            
        //}
        
        
        

        
        
        
        //setUser("123456");
        //setError("this is my error");


	}

    // const auth = getAuth();

    // const sendSignInLinkToEmailx = async (email) => {

    //     console.log("sendSignInLinkToEmail: ", email)

    //     getAuth().generate


    //     await sendSignInLinkToEmail(auth, email, {
    //         url: "http://localhost:3000/confirm",
    //         handleCodeInApp: true,
    //     }).then((response) => {
    //         console.log(response);
    //         console.log("returned back");
    //         return true;
    //     })

       

    // }

    // const signInWithEmailLink = (email, code) => {
    //     return AuthService.signInWithEmailLink(email, code).then(result => {

    //         setUser(result.user);
    //         return true;
    //     });
    // };



    
    const addFunds = async (userId, amount) => {
        const userRef =  doc(db, "users", userId)
        await updateDoc(userRef, {
            accountBalance: increment(amount)
        });


        //await updateDoc(userRef, {username: username,});
    };
    
    const logout = async () => {
        await AuthService.logout();
        setUser(null);
    };


// ... Subscribe to user on mount. Because this sets state in the callback 
// ... it will cause any component that utilizes this hook to re-render with the latest auth object
    // useEffect (() => {
    //     const unsubscribe = AuthService.onAuthStateChanged( user => { 
    //         setUser(user);
    //         setIsAuthenticating(false);
    //     });

    //     // Cleanup subscription on unmount
    //     //return () => unsubscribe();

    // }, []);




    const updateUsername = async (userId, username) => {
        const userRef =  doc(db, "users", userId)
        await updateDoc(userRef, {username: username,});
    };




    // const updateMyValue = async (msg) => {
    //    setMyValue(msg);
    // };

    const value = { 
        user, error, setError, showModal, setShowModal, modalMessage, setModalMessage,
        loginWithGoogle, loginWithiPing, forgotPassword, logout, 
        setUser, getUserInfo, userData, setUserData, 
        updateUsername, addFunds,
        currentPage, setCurrentPage, makeUserWithEmailAndPassword
        //, sendSignInLinkToEmailx, signInWithEmailLink, isAuthenticating,

    };
    
    return <authContext.Provider value={value} { ...props } />;
    
    // return (
    //     <authContext.Provider value ={value}>
    //         { !isAuthenticating && children }
    //     </authContext.Provider>
    // )

}