import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Cancel = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const redirectToHome = async () => {
    setLoading(true);
    console.log("redirect to Home");
  
    navigate("/");
  
    
    setLoading(false);
  };
 

   
    return (
       <div className="flex flex-col  justify-center  space-y-5 items-center bg-gray-900 text-gray-100 h-screen">

        

       
            <div className="font-bold flex flex-col space-y-2 justify-end items-center p-2">
              <div className="">Your purchase was canceled. </div>
              <div className=""></div>
            </div>
         
           
         
          

          <div className=" bg-gray-400 flex flex-col space-y-5  justify-start items-center w-[380px]  rounded-lg pb-5">

           <div className="flex  flex-row justify-start space-x-1 font-bold mt-4">
                           
                <div className="text-sm m-2">
                Canceled
                </div>

                


               
          </div>

          <div className="shadow-black shadow-lg rounded-lg">
            <img className="rounded-lg  " src={"/sacramenthidden.png"} width={250} height={250}/> 

          </div>

         


          <button type="submit" 
            onClick={redirectToHome}
            className="mt-4 rounded-lg px-4 py-2 focus:outline-none 
                      bg-blue-900 text-gray-200 text-sm
                      hover:bg-gray-500">Return to Bushman Sacrament NFT
                
          </button>

        </div>







       </div>
        
    )


}

export default Cancel

