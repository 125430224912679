import { useNav } from "../hooks/useNav"
import { motion } from "framer-motion"; //https://www.youtube.com/watch?v=-1ihhsv88O0&list=PLQKg8mIgoxKqTBBPf1cbVg20EceDUlcsm
import { fadeIn, staggerContainer, leafWrapper, leaf } from "../variants.tsx";


const Home = () => {
  const welcomeRef = useNav("Welcome")
//https://www.youtube.com/embed/VIDEO_ID?playlist=VIDEO_ID&loop=1
//https://developers.google.com/youtube/player_parameters
//https://www.npmjs.com/package/react-youtube
//https://cookpete.com/react-player/

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1},
  }

  const redirectToOpenseaCollection = async () => {
    
    console.log("redirect to Block Scanner");
  
    
    window.open(`https://opensea.io/collection/bushman-sacrament`, '_blank');
  
   
  };

  return (


    


//bg-[url('../public/glowleaf.png')]
//bg-gradient-to-r from-cyan-500 to-yellow-500
// width={1100} height={900} 
// //artgrid.io
    <section  className="h-[900px] md:h-[1000px]   grid justify-items-center  items-center bg-[url('../public/bgimageart.png')] relative bg-fixed bg-center  bg-no-repeat bg-cover text-white px-4 " ref={welcomeRef} id="welcomeSection">
     
   
    
    {/* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120vh; */}

 
  
     

     <motion.div
     variants={staggerContainer}  
     initial="initial"
     animate="animate" 
     >
      <div 
          
          className="grid gap-1 text-center">
        <motion.p variants={fadeIn("up")}  
         className="text-5xl  md:text-6xl font-bold  mt-20 md:mt-40 ">BUSHMAN NFT</motion.p>
        <p className="text-2xl font-bold mt-7 mb-5 ">SACRAMENT COLLECTION</p>
        <div className="flex justify-between items-start gap-2  font-bold">

                        <div>3 ALBUMS</div>
                        <div>3 VIRTUAL CONCERTS </div>
                        <div>1 LIVE FESTIVAL</div>

                    </div>
        <motion.p variants={fadeIn("up")}  className="text-md px-6 md:text-lg  max-w-2xl  min-w-lg ">

          
        A collection of 1000 unique artworks inspired by Bushman's upcoming <a  className="font-bold underline underline-offset-2" href="https://trifecta.live/" target="_blank" rel="noreferrer">Trifecta project</a>.
        Sacrament is the first of a 3 part community backed NFT collection series that will support live and recorded 
        Bushman music projects. This collection will empower Bushman and remove record company red tape, gate keepers, and creative restraints. 
      
        Our main focus is to provide an NFT with utility to Bushman fans around the world.
        </motion.p>
        </div>
      </motion.div>
      
      
      
      <motion.div 
        id="leaf" 
        variants={leafWrapper}  
        initial="initial"
        animate="animate" 
        className="-mt-32">
          <motion.img variants={leaf}  
            initial="initial"
            animate="animate" 
            className="" src={"/mainleaf.png"} width={550} height={450}/> 
      </motion.div>


      

      
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic optio dolorum exercitationem, harum repellat explicabo quis error excepturi porro soluta.</p> */}
    <div className="flex justify-evenly items-center gap-3 "> 
      <button onClick={() => {document.getElementById("mintSection").scrollIntoView({ behavior: "smooth" })}}className="bg-green-900 p-2 my-4  w-36 h-20 text-sm md:w-48 md:h-16 md:text-md   font-bold rounded-lg hover:bg-[#558d0b] hover:text-white">
        
        MINT <br/> <span className="text-xs md:text-sm">Thu. Sept 15th, 2022</span></button>


        <button 
        onClick={() => redirectToOpenseaCollection() }
        className="bg-green-900 p-2 my-4  w-36 h-20 text-sm md:w-48 md:h-16 md:text-md   font-bold rounded-lg hover:bg-[#237788] hover:text-white">
        
        View on <br />Opensea</button>
        </div>

        <div className="w-full h-[200px]"></div>
     
    
      {/* controls="controls" */}
   
      
     
    </section>

   
    
  )
}

export default Home