const ConfirmVerified = ({isValidUser, redirectToCheckout, isLoading}) => {
  
 

   
    return (
       <div className="flex flex-col  justify-center  space-y-5 items-center bg-gray-900 text-gray-100 h-screen">

         
            <div className="font-bold flex flex-col space-y-2 justify-end items-center p-2">
              <div className="">Thank You for verifying your email. </div>
              <div className="">Continue to purchase your Bushman NFT.</div>
            </div>
        

          <div className=" bg-gray-400 flex flex-col space-y-5  justify-start items-center w-[380px]  rounded-lg pb-5">

           <div className="flex  flex-row justify-start space-x-1 font-bold mt-4">
                           
                <div className="w-[200px]">
                Mint with Credit Card 
                </div>

                <img className="" src={"/ccs.png"} width={150}/> 


               
          </div>

          <div className="shadow-black shadow-lg rounded-lg">
            <img className="rounded-lg  " src={"/sacramenthidden.png"} width={250} height={250}/> 

          </div>

          <div className=" text-sm text-gray-800">
            Bushman Sacrament NFT: $100.00 <br/>
            + Service Fee: $25.00 <br/>
            <span className="font-bold">Total: $125.00</span>
          </div>


          <button type="submit" 
            onClick={redirectToCheckout}
            disabled={isLoading}
            className="mt-4 rounded-lg px-4 py-2 focus:outline-none 
                      bg-blue-900 text-gray-200 text-sm
                      hover:bg-gray-500">  
                      {isLoading ? "" : <span className="font-bold">Buy</span> } 
                      {isLoading ? "Loading..." : " with Credit/Debit card"} 
                
          </button>

        </div>







       </div>
        
    )


}

export default ConfirmVerified

