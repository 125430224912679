import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import {collection, onSnapshot, updateDoc, query, orderBy, doc, addDoc, setDoc,increment, getDoc, serverTimestamp} from "@firebase/firestore";
import { db } from "../config/firebase.config";
import ConfirmVerified from "./confirm-verified";
import ConfirmNotVerified from "./confirm-not-verified";

import emailjs from 'emailjs-com';

//import { addDoc, collection, serverTimestamp, updateDoc, doc, setDoc } from "@firebase/firestore";
//import AuthService from "../service/AuthService";


import useAuth from '../hook/auth';




let stripePromise;

const getStripe = () => {

    if (!stripePromise) {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);  //STRIPE_SECRET_KEY
    }
    
    return stripePromise
};






const Confirm = () => {
  const navigate = useNavigate();

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [ isValidUser, setIsValidUser ] = useState(false); //
  const [ userData, setUserData ] = useState(null); //
  const [ readyToUpdate, setReadyToUpdate ] = useState(false); //

  const [ tokenReferenceId, setTokenReferenceId ] = useState(''); //

  const params = useParams();
  const userId = params.id;



  //console.log(userId);
  

  const item = {
    price: "price_1LiIBkDRAJddC0Tx3wJ9rYJ4",  //"price_1Ll0etDRAJddC0Tx0zMMk7jO", //"price_1LiIBkDRAJddC0Tx3wJ9rYJ4", //"price_1LiIBkDRAJddC0Txz3lLkxKw", //"price_1LiHuNDRAJddC0TxV9c7s1vh", //"price_1LhPLaDRAJddC0Tx0q9pAryd", //"price_1KNzmADRAJddC0TxAxVDPinK",
    quantity: 1
  };

//test: prod_L482eqmvn2JhYD 
//live: prod_MRAWtTCKRaNrl7

//live: price_1LiIBkDRAJddC0Tx3wJ9rYJ4   -> $125.00
//live: price_1LiIBkDRAJddC0Txz3lLkxKw  -> $1.00 



  const saveToken = async (userId) => {

    //handleChangeId(userId);
    console.log("saving token for user: ", userId);

    const res = await addDoc(collection(db, "tokens"), {
     
      //id: userId,
      userId: userId,
      tokenId: '',
      minted: false,
      mintedDT: '',
      canceledDT: '',
      txFailedDT: '',
      timestamp: serverTimestamp() }
      );

    console.log('Added token document with ID: ', res.id);
    //setTokenReferenceId(res.id);

    return res.id;

    


  }




  

const redirectToCheckout = async () => {
  setLoading(true);
  console.log("redirectToCheckout");

  console.log("userData.id: ", userData.id);
  console.log("userId: ", userId);

  const resId = await saveToken(userData.id)
  //create a unique id and store it in the "tokens collections with the following fields:"
  // id=unique id
  // user= firebase auth userid
  // tokenId=will be null at first
  // minted= start off as false
  // mintedDT
  // timestamp
  // canceledDT
  // failedDT

  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/success/${resId}/${userId}`,
    cancelUrl: `${window.location.origin}/cancel/${resId}`
  };



  const stripe = await getStripe();
  const { error } = await stripe.redirectToCheckout(checkoutOptions);
  console.log("Stripe checkout error", error);

  if (error) setStripeError(error.message);
  setLoading(false);
};

const redirectToHome = async () => {
  setLoading(true);
  console.log("redirect to Home");

  navigate("../../");

  
  setLoading(false);
};








const updateUserVerified =  (userId) => {

  const docRef = doc(db, "users", userId);
  //const todoUpdated =  { ...todo, timestamp: serverTimestamp() }


  updateDoc(docRef, { emailValidated: true, emailValidatedDT: serverTimestamp() } );



  

  setIsValidUser(true);
  
  }



  

    useEffect(  ()  =>  {


      //const getUserInfo =  (userId) => {
        
         onSnapshot(doc(db, "users", userId), (doc) => {

        console.log("we got the data");

        //console.log(doc.data());


        setUserData(doc.data());


      //const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      //console.log(source, " data: ", doc.data());
            //setUserData(doc.data());
       
            //console.log("inside onSnapshot ", doc.data()?.id  + " test");
      
        //if (doc.data()?.id === userId && !isValidUser )
        // if (doc.data()?.id === userId )
        // {
          
      
      
        //   updateUserVerified(userId);
        //   setIsValidUser(true);
      
        //   console.log("here's the user");
        //   //console.log(doc.data());
        // }
        // else
        // {
        //   console.log("id !== userId ");
        //   //setIsValidUser(false);
        // }
      
      })
      
      
      
     // }

     

      
      //console.log("params.id: ", params.id);
      //const userId = params.id;
      

      //if (!isValidUser)
      //{
           //getUserInfo(userId);

        
      //}

      //console.log("here is the loop");


    }, []);


    useEffect(  ()  =>  {
      console.log("my userdata id: ", userData?.id);
      //const params = useParams();

      if (userData?.id === userId)
      {
        //updateUserVerified(userId);
        setReadyToUpdate(true);
        console.log("setReadyToUpdate");
      }

    }, [userData]);

    useEffect(  ()  =>  {
      if (userData?.id === userId)
      {
        updateUserVerified(userId);
        console.log("updated");
      }

    }, [readyToUpdate]);


   
    return (
      
       <div>

  { isValidUser && (
         <ConfirmVerified redirectToCheckout={redirectToCheckout} isLoading={isLoading}/> 
    )}

{ !isValidUser && (
         <ConfirmNotVerified redirectToHome={redirectToHome} isLoading={isLoading} code={userId}/> 
    )}
       </div>

        
    )


}

export default Confirm

