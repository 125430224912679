import { useEffect } from "react";
import { useNav } from "../hooks/useNav"
import Collage from "../components/collage";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion"; 
import { artwork } from "../variants.tsx";

const Artwork = () => {
  const artworkRef = useNav("Artwork")
  //const {ref, inView } = useInView({ threshold: 0.2});
  //const [element, controls] = useScroll();

  const {ref, inView } = useInView({ threshold: 0.2});
  const animation = useAnimation();
  const animation2 = useAnimation();

  useEffect (() => {

    if(inView) {

      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.3
        }
      });

      animation2.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.3
        }
      });

    }

    if(!inView) {

      animation.start({x: '-100vw'});
      animation2.start({x: '100vw'});
    }

    //console.log("user effect hook, inView = ", inView)
    
  }, [inView]);

  return (

    
    <section className="h-[1100px]  md:h-[1100px]  bg-black   lg:bg-[url('../public/background.png')] relative bg-fixed bg-center  bg-no-repeat bg-cover gap-4 text-white" 
    ref={artworkRef} id="artworkSection">


    <div  ref={ref} className="text-white mt-16 md:mt-36">
       <div animate={animation2} className="sectionHeader">COLLECTION PREVIEW</div>
    </div>

      
      <div className=" grid   overflow-scroll lg:grid-cols-2 xl:grid-cols-4 justify-items items-center gap-3   ">
      {/* <Collage /> */}
      
      <img className="rounded-lg" src={"/nfts/70.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/1863.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/1821.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/452.png"} width={300} height={300}/> 
      
      <img className="rounded-lg" src={"/nfts/1824.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/golden.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/164.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/1990.png"} width={300} height={300}/> 
      
      <img className="rounded-lg" src={"/nfts/1977.png"} width={300} height={300}/>
      <img className="rounded-lg" src={"/nfts/1948.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/1820.png"} width={300} height={300}/> 
      <img className="rounded-lg" src={"/nfts/226.png"} width={300} height={300}/> 

      {/* <img className="sm:visible lg:visible rounded-lg" src={"/bushmancollage.png"} width={300} height={300}/>  */}
      {/* <img className="hidden sm:visible md:hidden lg:visible xl:hidden rounded-lg" src={"/bushmancollage.png"} width={300} height={300}/>  */}
      {/* <div className="bg-red-500 sm:bg-green-500 md:bg_blue-500 lg:bg-pink-500 xl:bg-teal-500 h-24 w-48">sfgsdfgsdfg </div> */}
     </div>
     
    </section>
  )
}

export default Artwork