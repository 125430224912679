import { useContext, useState, useEffect } from 'react';
import { NavContext } from '../context/NavContext';
import { Web3AccountContext } from '../context/Web3AccountContext';
import styles from '../styles/Header.module.css'
import { motion } from "framer-motion";
import { fadeIn, fadeInHorizontal } from "../variants.tsx";
import { SiDiscord, SiTwitter, SiInstagram, SiEthereum } from 'react-icons/si';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ModalHamburger } from "./modal-hamburger";
import { ModalMintWithCC } from "./modal-mint-with-cc";
import { useNavigate } from 'react-router-dom';

import Web3Modal from "web3modal";
import { ethers } from "ethers";
//import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
//import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'




const HeaderListing = () => {
  const navigate = useNavigate();

  const [header, setHeader] = useState(false);
  const { activeLinkId } = useContext(NavContext);
  const { userAccount, setUserAccount, connectWeb3 } = useContext(Web3AccountContext);

  const [showModal, setShowModal] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [modalMessage, setModalMessage] = useState(true)
  // const [ showModalMintWithCC, setShowModalMintWithCC] = useState(false);

  const [web3Modal, setWeb3Modal] = useState({});
  //const [userAccount, setUserAccount] = useState("");

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const web3modal = new Web3Modal({
  //       network: "mumbai", // optional
  //       cacheProvider: false, // optional
  //       providerOptions, // required
  //     });
  //     setWeb3Modal(web3modal);



  //     web3modal.on("accountsChanged", (accounts) => {
  //       console.log(accounts);
  //     });



  //   }
  // }, []);


  const toggleAccountMenu = () => {
    setShowAccountMenu(!showAccountMenu)
   

  }

  const logOut = async () => {
    setShowAccountMenu(false)

    setUserAccount("")

   
  
   

  }




//%%
  // const providerOptions = {
  //   walletconnect: {
  //     package: WalletConnectProvider, // required
  //     options: {
  //       //infuraId: process.env.REACT_APP_INFURA_ID, // required
  //       infuraId: "",
  //       80001: "https://rpc-mumbai.matic.today",
  //     },
  //   },
  // };



  const connect3 = async () => {

    var win = window.open("metamask://dapp/nft-minter-tau.vercel.app", '_blank');
    //var win = window.open("https://nft-minter-tau.vercel.app", '_blank');
    win.focus();


  }

  const connect2 = async () => {


    var win = window.open("https://metamask.app.link/dapp/nft-minter-tau.vercel.app", '_blank');
    //var win = window.open("https://nft-minter-tau.vercel.app", '_blank');
    win.focus();

  }


  const connect = async () => {
    try {
      const instance = await web3Modal.connect();
      //console.log(instance);

      //console.log("test: " + instance.eth.getAccounts());
      //
      // console.log(instance)

      const provider = new ethers.providers.Web3Provider(instance);


      instance.on("accountsChanged", (accounts) => {
        console.log("myaccounts: " + accounts);
        setUserAccount(accounts)
      });

      // Subscribe to chainId change
      instance.on("chainChanged", (chainId) => {
        console.log(chainId);
      });

      // Subscribe to session disconnection
      instance.on("disconnect", (code, reason) => {
        console.log(code, reason);
      });



      //console.log(provider.getNetwork());
      //console.log(provider.provider._state.accounts);

      //let signer = await provider.getSigner();
      //let balance = await signer.getBalance();
      //console.log("balance: " + balance)

      const network = await provider._networkPromise;
      //console.log(network.name);
      //console.log(network.chainId);

      console.log(network);

      setUserAccount(instance.selectedAddress);
      //const accounts = await provider.listAccounts();

      //alert("account: " + accounts.current);
      //setUserAccount(accounts.current);
    } catch (err) {
      console.log(err);
    }
  };




  const navLinks = ["Listing", "Mint"];
  const handleClickLogo = () => {
    document.getElementById("listingSection").scrollIntoView({ behavior: "smooth" })
  }

  const renderNavLink = (content) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    const handleClickNav = () => {
      console.log("clicked Nav: ", scrollToId)

      if (scrollToId == 'listingSection') {
        document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" })
      }

      if (scrollToId == 'mintSection') {
        //document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" })
        //window.open(``, '_blank');
        navigate("/");
      }

    }

    const changeBackground = () => {
      //console.log(window.scrollY);
      if (window.scrollY >= 80) {
        setHeader(true);
      } else {
        setHeader(false);
      }

    }

    window.addEventListener('scroll', changeBackground);



    return (
      <motion.ul className=""
        variants={fadeIn("down")}
        initial="initial"
        animate="animate"
        key={content}>
        <li>
          <button
            onClick={handleClickNav}
            className={activeLinkId === content ? styles.activeClass : ""}
          >{content}</button>
        </li>
      </motion.ul>
    )
  }

  return (

    /*
    background: none;
  color: #fff;
  padding: 1rem;
  position: fixed;
  width: 100%;
  */
    // <header className={header ? styles.headerActive : styles.header }>
    <header className={header ? 'headerActive' : 'header' + ''}>
      <div className="">  {/* {styles.container} */}


        <ModalHamburger className="-ml-96 md:-ml-0" showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage} />


        {/* <ModalMintWithCC className="-ml-96 md:-ml-0" showModal={showModalMintWithCC} setShowModal={setShowModalMintWithCC} modalMessage={modalMessage} /> */}

        <div className="flex justify-between visible  md:invisible w-[350px]">
          <motion.img className="" variants={fadeIn("down")}
            initial="initial"
            animate="animate" src={"/bushmansacramentlogo.png"} width={250} />

          <GiHamburgerMenu className="mr-2 hover:text-gray-100  hover:opacity-60" size={30} onClick={() => { setShowModal(true) }} />
        </div>

        <div className={styles.navContainer}>
          <p className="  text-lg  cursor-pointer md:text-4xl" onClick={handleClickLogo}>

            <motion.img variants={fadeIn("down")}
              initial="initial"
              animate="animate"

              className="invisible md:visible  -mt-2" src={"/bushmansacramentlogo.png"} width={250} />
          </p>



          <div className="invisible  md:visible">
            <nav className="">

              {navLinks.map(nav => renderNavLink(nav))}


              <motion.div
                variants={fadeInHorizontal("left")}
                initial="initial"
                animate="animate"
                className="flex space-x-2">

                <a target="_blank" href="http://twitter.com/nyahmanbushman?s=11&t=IQoL2-59AqT5pnJIyyUuug">
                  <SiTwitter className="mr-2 hover:text-green-700  hover:opacity-60" size={21} />
                </a>
                <a target="_blank" href="https://instagram.com/original_bushman?igshid=YmMyMTA2M2Y=">
                  <SiInstagram className="mr-2 hover:text-green-700  hover:opacity-60" size={21} />
                </a>

                <a target="_blank" href="https://discord.gg/AKyWrehkRj">
                  <SiDiscord className="mr-2 hover:text-green-700  hover:opacity-60" size={21} />
                </a>

              </motion.div>





              {userAccount == "" &&
                <div className=" bg-green-900  rounded-md p-2  -translate-y-[10px]">
                  <button className="" onClick={connectWeb3}>
                    Connect
                  </button>
                </div>

              }

              {userAccount !== "" &&

                     
                <div className=""> 

                  <div className="cursor-pointer" onClick={() => {toggleAccountMenu()}} >
                  <Jazzicon  diameter={25} seed={jsNumberForAddress(userAccount)} />
                  </div>
                  
                  <div className={ (showAccountMenu ? "visible ": "invisible " ) + " outline outline-offset-1 outline-1 outline-white/20 grid grid-cols-1  divide-y divide-white/20 rounded-lg text-center  align-text-top text-sm  -translate-x-[170px]  absolute w-[200px]  bg-gray-900/70 "}>
                    <div className="p-2 text-xs font-bold bg-gray-600/90">ACCOUNT </div> 
                    <div className="p-2  cursor-pointer hover:bg-green-800/20" onClick={() => {logOut()}} > Log Out </div> 
                  </div>
                
                 </div>


              }

             



            </nav>






          </div>



        </div>

      </div>


    </header>
  )
}

export default HeaderListing