import { memo, useEffect, useState, useContext } from 'react'
import {motion, AnimatePresence } from 'framer-motion'
import NavBtnsHamburger from './nav-btns-hamburger'
import ContactForm from './contact-form'
import { AiFillCloseCircle} from 'react-icons/ai';
import {IoMdClose} from 'react-icons/io';

const backdrop = {

    visible: {opacity: 1},
    hidden: {opacity: 0}
}

const modal = {

    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "0px",
        opacity: 1,
        transition: {delay: 0.5}
    },
}


//export const App = memo(({auth, showSignup, showModal, setShowModal}) => {
//export const Form = memo((props, useAuth) => {
//export const ModalHamburger = memo(({showModal, setShowModal, modalMessage}) => {
    
export const ModalMintWithCC = ({showModal, setShowModal, modalMessage}) => {
//export const Modal = ({ showModal, setShowModal }) => {

//  const { showModal} = auth;


    useEffect(() => {
       
        //setShowModal(true);

        //console.log("inside modal.js showModal" + showModal);

        //console.log(auth)



    }, [])



    return (

        
        
        
      
        
        <div>
        

            { showModal &&  (

                <motion.div className="bg-gray-9s00  text-gray-100 bg-opacity-90 w-screen h-screen flex justify-center items-center"   
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden">

                   


                    
                    <motion.div className="bg-gray-800 bg-opacity-90  rounded-lg -mt-[200px] h-72 w-[400px]  
                     flex flex-col  justify-center items-center  overflow-visible gap-10"
                     variants={modal}
                    >
                        {/* <NavBtnsHamburger setShowModal={setShowModal}/> */}
                        
                       
                            
                            {/*                             
                            <button onClick={() => {setShowModal(false)}} className="
                            -mt-[100px] translate-x-[180px] 
                            border border-gray-500 
                            w-10 h-10 text-black  rounded-full"> 
                            <AiFillCloseCircle className="  text-gray-100/50  hover:text-gray-50  hover:opacity-60" size={40}  onClick={() => { setShowModal(false) }} /> 

                            </button> */}

                       
                        <div className="flex justify-end items-center w-full "> 
                            
                            <div className="bg-white/20 rounded-full p-2">
                            <IoMdClose 
                                onClick={()=> {setShowModal(false)}}
                                size={25} 
                                className="cursor-pointer" />
                            </div>
                        
                        </div>
                        
                        <div className="flex  flex-row justify-start gap-2 font-bold w-full pl-4">
                           
                            
                           

                            <div className="w-[200px]">
                            Mint with Credit Card 
                            </div>

                            <img className="" src={"/ccs.png"} width={150}/> 
                       </div>
                    
                        <div className="">
                        <ContactForm/>
                        </div>
                        
                        
                    </motion.div>

                </motion.div>


            )}

</div>
        


    )

}
//);


//export default Modal;