import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef,  } from 'react';
import { BigNumber, ethers, BigInt } from "ethers";
import { abi } from "../abi"
import emailjs from 'emailjs-com';
import { onSnapshot, doc, serverTimestamp, updateDoc, query, collection, where } from "@firebase/firestore";
import { db } from "../config/firebase.config";

//Progress Bar
//https://mui.com/material-ui/react-progress/
//import LinearProgress  from '@material-ui/core';


import LinearProgress from '@mui/material/LinearProgress';
// or
//import { LinearProgress } from '@mui/material';

const Success = () => {  //successful Strip Payment
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [minting, setMinting ] = useState(true);
  const [tokenId, setTokenId] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [requestAlreadyMinted, setRequestAlreadyMinted] = useState(false);
  //const [refTokenId, setRefTokenId] = useState("");
  const [tokenData, setTokenData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);

  const [userEmail, setUserEmail] = useState("");
  

  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});

   const params = useParams();
   const refTokenId = params.id;
   const userId = params.userId

  //const { refTokenId, userId } = useParams();

  //console.log(refTokenId);


  const [values, setValues] = useState({
    email: '',
    product: 'Bushman Sacrament NFT',
    intro: 'Thank you for purchasing',
    support: 'Sacrament Support Team',
    id: ''
  });

  const [message, setMessage] = useState(''); //
  const [status, setStatus] = useState('');



  

  useEffect(() => {
  
    // const q = query(collection(db, "users"), where("id", "==", userId)); //raAByCD9odasOWFSOBw5Ra023Jm1 //ZTYYejHgamcK7h4xjVnqHBGQt0M2

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {


     
    //   querySnapshot.forEach((doc) => { //refactor to get just one user
        
    //     const email = doc.data().email;
    //     if (email != "" )
    //     {
    //       setUserEmail(email);
    //     }
    //     console.log("user email: " + email);
    //   });
      


    // });
    //2izSuwmmvPcr2Wj8Ba7C5ReUH5g2
  

  }, []);


  
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  const progress2Ref = useRef(() => {});
  useEffect(() => {
    progress2Ref.current = () => {
      //console.log("progress2 here")
      if (progress2 > 800) {
        setProgress2(100);
       
      } else {
       
        setProgress2(progress2 + 100);
       
      }
    };
  });


  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [])


  useEffect(() => {
    const timer = setInterval(() => {
      progress2Ref.current();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [])

  const redirectToHome = async () => {
    setLoading(true);
    console.log("redirect to Home");
  
    navigate("/list-tokens/" + userId);
  
    setLoading(false);
  };


  const redirectToBlockScanner = async (transactionHash) => {
    setLoading(true);
    console.log("redirect to Block Scanner");
  
    
    window.open(`https://polygonscan.com/tx/${transactionHash}`, '_blank');
  
    setLoading(false);
  };

  const redirectToOpensea = async (tokenId) => {
    setLoading(true);
    console.log("redirect to Block Scanner");
  
    
    //window.open(`https://testnets.opensea.io/assets/mumbai/0x9c55ed957e525503173e21045bfa160b6fb2088e/${tokenId}`, '_blank');
    window.open(`https://opensea.io/assets/matic/0xd8501a6458eb8a1b511571b174e4576df132ff0c/${tokenId}`, '_blank');

    
  
    setLoading(false);
  };

  


/*


















https://opensea.io/collection/yolofantasy




0x7049105d28f8acc0bf34f2a135e54e3f184e2090 : finlit.eth
0x13b9540Ee90558C7929839731405f0B2C354a607 : nafty
0x33D982EFd49ec05d37364533cAFb38260DF2659D
0x659adca5cc0179bf27fa4ee9c98b6a7b2ccd2c0a : kiwanlandrethsmith.eth
0x6C1667b508D712976069AEdCDb34cf74da095606 :  thecil.eth
0x45A2235b9027eaB23FfcF759c893763F0019cBff : DoctorDegen
0x03A71968491d55603FFe1b11A9e23eF013f75bCF : CheCrypto
0xE3506A409Fc03eD50D12143E98A8aDA2B8a40a5f : HoooH
0x46B7565dc594A4a2175F9CCF027D1a0BE544a7cD : JahRanks
0x802a1fD761ECa0B054aD8d000D370C3170E88273 : 310C
0x3cAD9e406dAcB4D2EFA99AdFf73f0356Fd0cBb8A :
0x708a0eAB6Dc82aeE59F4dAa429CAEA6D11459ABA
0xd2200AD6e4E46d045004759c5bA8bc2e39f71933 : treepoetry.eth
0x4cc2E6ff22e7B70Ca5bD2FB651a56955713b2d61 : 100DollarShills
0xe6242fe484Fb2A10cbD059f61a110F59FfE6cB9e
0x035fCf9D6578faEC4D327c46b973EA0e6D1A042E : rastabusiness
0x373f18D3ef332E28f9767cFc1dA5b0dd77A67349 :  yiwulife.eth
0x233aDb2937f53772824338d20a966469708A42ff : jahhweh.eth
0x097E4741ba01cb0e5a869D8D3EF63083e83204A5 : mrmagic.eth
0x49260c1bbec65b0399ef129e0de475cedbf7a187 : illiquidater.eth
0xacb818130ac9cd4484848848a0ca3e7dda4bb265 : buyonthedip.eth
0x42fe258c554c13746b7c6fb81a184aeb44c64169 : freemiumgamer.eth
0xb5b8f8d1091967ef759a991005863bd0de581fbe : *♥you.eth
0x6132f7c243f1e3ce5741bd8f9d7204a71c041740 : dreamclothing.eth
0x29e9b2058e9f4ae78040a57f6a1be0366b969e43 : trahh.eth
0x25d1427733ae41552135d42a7c8bd7a362fc8e30 : 1st2nft.eth
0xbdd95abe8a7694ccd77143376b0fbea183e6a740 : washedninjapants.eth
0x2edda6b5fc27efc270847f114e8e7ce13eed6d57 : wowowow.eth
0xac70180bd527e4ccb3d8049c7e7d4b7d6d0e4110 : tescomealdeal.eth
0x392027fdc620d397ca27f0c1c3dcb592f27a4dc3 : blokku.eth
0x6428d1cadd917db40b5289a296f89f25d969c496 : ruffryder.eth
0x1647fe283c4546f3de465ad895bc659fad7144e0 : sarahgene.eth
0xc1c059c3d14cfeb7b02c9649c274496e089fff3e : fumblebag.eth
0x05Ee1EE157d9a1c62EE8a671BA2AbEAc0aE6f2D5 : melda-vnh.eth
0x15fd7178cabed50e9008cb42b474621e9b07cd5f : ezraigor.eth  ########
0x2a26877f0f78d5ed8c6a8f36d29199583492339d : metatrish.eth
0x58ce3c030ceda4a1141b2d21bfeffdbf44bff921 : isaga.eth
0xEb20DA0928E0D504f52e2a6017B8961C16F49969 : 
0x9260ae742f44b7a2e9472f5c299aa0432b3502fa : harveybirdman.eth
0x27013982436C909a685C4e33A768eA0Bb671fd73 : VechKnight_Vault
0x3ae611bcda8f14ffb645b2c129f499692f767534 : aneo.eth
0xba5626a31c4844ac03ddc5e93aa7fa3045d43bc5 : 
https://opensea.io/collection/cryptocannabisclub
0xcEE1837fd75C099Fc0D0e0939a4c376F8856fd8E : WilliePiff
0x4c956a0adfe992ed422d0be40ea488f18bf66a67 : lazyiv.eth
0x80c5cae1fc0b6a548ea16fe40171d01555f4f57e : tropicalvirtual.eth
0xbd74c35965465512347a3d520deaa32f458e058b : slashburn.eth
0x01818ef4853f3e394ceb6f0798063c7d311f654e : gr3atness.eth
0x5741359bbc889a768d54d2f97a6cb18dee1ec341 : 001gm.eth
0x11bf166546468d2c60519229d1840d141f8cf7f3 : papervacuum.eth
0x5cf6e510e0c123e1e839376f8ea1900bb9b9a6c1 : wisekim.eth


0x9fce8eb77fb67660cb134f4ee4c82a48f415f812 : snoop dogg
0xce90a7949bb78892f159f428d0dc23a8e3584d75 : snoop dogg
0x9114b66e4bd387eb832d1477e86ede0cf7f76115 : snoop dogg
0x7217bc604476859303a27f111b187526231a300c : snoop dogg

0xd6a984153acb6c9e2d788f08c2465a1358bb89a7 : gary v
0xce90a7949bb78892f159f428d0dc23a8e3584d75 : gary v
0x9114b66e4bd387eb832d1477e86ede0cf7f76115 : gary v
0x7217bc604476859303a27f111b187526231a300c : gary v 




0x7217bc604476859303a27f111b187526231a300c : mike tyson
0x9114b66e4bd387eb832d1477e86ede0cf7f76115 : seth curry
0x7217bc604476859303a27f111b187526231a300c : seth curry

0x3c6aeff92b4b35c2e1b196b57d0f8ffb56884a17 : shaq
0xd6a984153acb6c9e2d788f08c2465a1358bb89a7 : shaq
0xce90a7949bb78892f159f428d0dc23a8e3584d75 : shaq
0x9114b66e4bd387eb832d1477e86ede0cf7f76115 : shaq
0x7217bc604476859303a27f111b187526231a300c : shaq








make website with pictures of celebrities and there eth address.

https://www.breadcrumbs.app/reports/760

The ethereum address of stars

https://dappradar.com/blog/celebrity-wallets-a-dive-into-crypto-hollywood
https://dappradar.com/blog/celebrity-wallets-a-dive-into-crypto-hollywood





  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await MyAPI.getData(someId);
      // ...
    }
    fetchData();
  }, [someId]); // Or [] if effect doesn't need props or state

  Learn more about data fetching with Hooks: https://reactjs.org/link/hooks-data-fetching
  */

  useEffect( ()  => {

    //  const pageAccessedByReload = (
    //   (window.performance.navigation && window.performance.navigation.type === 1) ||
    //     window.performance
    //       .getEntriesByType('navigation')
    //       .map((nav) => nav.type)
    //       .includes('reload')
    // );
    
    // alert(pageAccessedByReload);


  //   function navigationType () {
  //     console.log("entering navigationType");
  //       var result = 4
  //     //  var p;
  
  //     if (window.performance.navigation) {
  //         result=window.performance.navigation;
  //         if (result==255){result=4} // 4 is my invention!
  //     }
  
  //     if (window.performance.getEntriesByType("navigation")){
  //         //const p=window.performance.getEntriesByType("navigation")[0].type;
  //       console.log("getEntriesByType: ", window.performance.getEntriesByType("navigation"))
  //       //  if (p=='navigate'){result=0}
  //       //  if (p=='reload'){result=1}
  //       //  if (p=='back_forward'){result=2}
  //       //  if (p=='prerender'){result=3} //3 is my invention!
  //     }
  //     return result;
  // }

  //alert(navigationType());

  //Check if page gets reloaded or refreshed in JavaScript
  //https://stackoverflow.com/questions/5004978/check-if-page-gets-reloaded-or-refreshed-in-javascript
  // if (window.performance) {
  //   console.info("window.performance works fine on this browser");
  //   console.info(performance.navigation.type);
  // }

    //console.log("refTokenId ", refTokenId)
    // check if the refTokenId exists in Firestore "tokens" collection.
    //if it exists and not minted=true, then we will sendTransaction()

    let isValidToken = false;
    onSnapshot(doc(db, "tokens", refTokenId), (doc) => {
      console.log("we got the data" );
      console.log("doc.data(): ", doc.data());
      console.log("doc.id: ", doc.id);
      console.log("minting: ", minting);



      if (minting &&
        refTokenId === doc.id && 
        doc.data()?.minted == false)
      {
        isValidToken = true;

        sendTransaction()
        
      console.log("we will sendTransaction()");
      setTokenData(doc.data());
      }
      else
      {
        console.log("we will NOT sendTransaction()");

        //Your token request was invalid or was already minted.
        setMinting(false);
        setRequestAlreadyMinted(true);

        setTokenId(doc.data().tokenId);
        setBlockNumber(doc.data().blockNumber);
        setTransactionHash(doc.data().transactionHash);


      }
      
    })

    //if (minting && performance.navigation.type == 0) {
    // if ( minting && isValidToken ) {
    //   //sendTransaction()
    //   console.log("we will sendTransaction()");
    // }
    // else {
    //   console.log("token not valid  or has already been minted"); 
    //   console.log("isValidToken: ", isValidToken)
    //   console.log("minting: ", minting)
    // }
    
  }, [])


  useEffect(  ()  =>  {
     
  }, [])


  

  const sendEmailTokenInfo = async (userId, xemail) => {


    let email = "";
    const q = query(collection(db, "users"), where("id", "==", userId)); //raAByCD9odasOWFSOBw5Ra023Jm1 //ZTYYejHgamcK7h4xjVnqHBGQt0M2

    const unsubscribe = onSnapshot(q, (querySnapshot) => {


     
       querySnapshot.forEach((doc) => { //refactor to get just one user
        
         email = doc.data().email;
        if (email != "" )
        {
          setUserEmail(email);
          values.id = userId;
          values.email = email; //userEmail; //email //"ds@iping.com"; //userEmail
          console.log("values id: ", values.id)
          console.log("values email: ", values.email)
      
          emailjs.send('service_gbhj2ao', 'template_5v4s7ts', values, 't3uarFw-hOHHUnEe5')
            .then(response => {
              //console.log('SUCCESS!', response);
              //console.log("myvalues: ", values);
              setStatus('SUCCESS');
            }, error => {
              console.log('FAILED...', error);
            });


        }
        console.log("user email: " + email);
      });
      


    });



//QrAAzl0Yo7gyHILUkp6BK2Qa4Lp1
//https://sacrament.live/confirm/QrAAzl0Yo7gyHILUkp6BK2Qa4Lp1
//http://localhost:3000/confirm/QrAAzl0Yo7gyHILUkp6BK2Qa4Lp1
//http://localhost:3000/success/nfVsGWShu1sKSc6hDmKf/QrAAzl0Yo7gyHILUkp6BK2Qa4Lp1
//http://localhost:3000/list-tokens/QrAAzl0Yo7gyHILUkp6BK2Qa4Lp1

//Continue U1zpAXkZTghVd1a1RFgxldz2M1G3

//http://localhost:3000/confirm/U1zpAXkZTghVd1a1RFgxldz2M1G3
//http://localhost:3000/confirm/2izSuwmmvPcr2Wj8Ba7C5ReUH5g2
//http://localhost:3000/confirm/zZR1wgIWmyYMEgznqQhbpExFVd82

//http://localhost:3000/success/rIutXQ791kUS1csmZpSE/zZR1wgIWmyYMEgznqQhbpExFVd82


//http://localhost:3000/confirm/DpkozyE3XocyZmcU6nyGTRBstk63

//http://localhost:3000/confirm/hoVFcTfSPRSv9GWlSyMFPXz5P012

//http://localhost:3000/confirm/FZUVnhE2aKfxyPzgbvSWJdKzkm02

//http://localhost:3000/confirm/ClLqzcxIGIXGNNx2ZEsdBXJUYEs1

//http://localhost:3000/confirm/hnS1RNvuqZYlcVb4IPv4YvuCfvh1

    // values.id = userId;
    // values.email = userEmail; //email //"ds@iping.com"; //userEmail
    // console.log("values id: ", values.id)
    // console.log("values email: ", values.email)

    // await emailjs.send('service_gbhj2ao', 'template_5v4s7ts', values, 't3uarFw-hOHHUnEe5')
    //   .then(response => {
    //     //console.log('SUCCESS!', response);
    //     //console.log("myvalues: ", values);
    //     setStatus('SUCCESS');
    //   }, error => {
    //     console.log('FAILED...', error);
    //   });



  };


  const sendTransaction = async () => {

   
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_NETWORK_MAINNET_URL);
    
    

    const gasPrice = await provider.getGasPrice()
  
    const feeData = await provider.getFeeData()
    
    const wallet = new ethers.Wallet(process.env.REACT_APP_NETWORK_MAINNET_PRIVATE_KEY, provider);
    const signer = wallet.connect(provider);
    const nftContractAddress = "0xD8501a6458EB8A1B511571b174e4576dF132fF0C";
    const nftContract = new ethers.Contract(nftContractAddress, abi, signer)
    const contractOwner = await nftContract.owner();

    const blockNumber = await provider.getBlockNumber();
    const contractName = await nftContract.name();
    const tokenURI = await nftContract.tokenURI(2);
    const balance = await provider.getBalance("0x3907d7c8Ab29198EE26629771c229C4D56fB66be");
    const totalSupply = await nftContract.totalSupply();

    //console.log("totalSupply: ", totalSupply);

//make sure we don't allow the user to refresh his page an run this mint again.
// also store the Token ID (a number between 1-1000) in the users array of custodial nfts
let signedTxn = await nftContract.mintForAddress(1, "0x3907d7c8Ab29198EE26629771c229C4D56fB66be", {
    gasPrice: gasPrice,
    gasLimit: ethers.utils.hexlify(80000), //(21000), //100000 wei = 100 qwei // this is also called "gas"
    nonce: provider.getTransactionCount(wallet.address, 'latest'),
  
  });


console.log("waiting for receipt")

  // let signedTxn =  signer.sendTransaction(rawTxn)
let receipt = (await signedTxn).wait()

if (receipt) {
    (await receipt)
    console.log("the receipt is ready");

    console.log("Transaction is successful!!!" + '\n' + "Transaction Hash:", (await signedTxn) + '\n' 
    + "Block Number: " + (await receipt).blockNumber 
    + '\n' + "Navigate to https://polygonscan.com/tx/" 
    //+ '\n' + "Navigate to https://mumbai.polygonscan.com/tx/" 
    + (await signedTxn).hash, "to see your transaction")

    const tokenId = (await receipt).events[0].args.tokenId.toString();
    const blockNumber = (await receipt).blockNumber.toString();
    const transactionHash  = (await signedTxn).hash.toString();
    setTokenId(tokenId);
    setBlockNumber(blockNumber);
    setTransactionHash(transactionHash);
    //console.log((await receipt).events[0].args.tokenId);
    
    
    console.log(  "tokenId: ", tokenId ) ; 
    setMinting(false);

    //update minted to true
    const docRef = doc(db, "tokens", refTokenId);
    updateDoc(docRef, { 
      minted: true, 
      mintedDT: serverTimestamp(), 
      tokenId: tokenId,
      blockNumber: blockNumber,
      transactionHash: transactionHash
    }); 




    sendEmailTokenInfo(userId, userEmail)


  } else {
    console.log("Error submitting transaction")

    const docRef = doc(db, "tokens", refTokenId);
    updateDoc(docRef, { minted: false, txFailedDT: serverTimestamp() } ); 
}

      



    }
 
   


    return (
      
       <div className="flex flex-col  justify-center  space-y-5 items-center bg-gray-800 text-gray-100 h-screen">



<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
/>

       
            <div className="font-bold flex flex-col space-y-2 justify-end items-center p-2">
              <div className=""></div>
              <div className=""></div>
            </div>
            

            <div className="ml-[100px], mt-[100px]">

              
          </div>
         
           
         
          

          <div className=" bg-gray-400 flex flex-col space-y-5  justify-start items-center w-[380px]  rounded-lg pb-10">

           <div className="flex  flex-row justify-start space-x-1 font-bold mt-4">
                           
          { minting && (<div className="text-md m-2">
                Minting your Bushman Sacrament NFT
              </div>)}


          {/* { !minting && !requestAlreadyMinted && ( */}
          { !minting && (
          <div>
          <div className="text-md m-2">
            Congratulations!!!
          </div>
          <div className="text-md m-2">
            Your NFT was successfully minted.
          </div>
          </div>
        
        )}


        {/* { !minting &&  requestAlreadyMinted && (
            <div className="text-black">
            <div className="text-md m-2">
              Invalid Token Request !!!
            </div>
            <div className="text-md m-2">
              Your NFT request is invalid or already minted. 
            </div>
            </div>
          
          )} */}





                

                


               
          </div>

          {/* <div>
                 <a href="http://localhost:3000/success"> reload from a link</a>
              </div> */}

          { minting && (
              <div><LinearProgress variant='buffer' valueBuffer={buffer} color="success" value={progress} style={{ width: 280}}/> </div>  
          )}

          { minting && (

          <div className={"from-green-" + progress2.toString()  + " bg-gradient-to-tl to-gray-800 shadow-black shadow-lg rounded-lg"}>
              <img className="mix-blend-overlay rounded-lg" src={"/sacramenthidden.png"} width={300} height={300}/> 
            </div>
          )}

          {/* { !minting && requestAlreadyMinted && (
          
         
              <div className="shadow-black shadow-lg rounded-lg">
                <img className="rounded-lg" src={"/sacramenthidden.png"} width={250} height={250}/> 
              </div>

          )} */}

          { !minting  && (
              
            
              <div className="shadow-black shadow-lg rounded-lg">
                <img className="rounded-lg" src={`https://sacrament.mypinata.cloud/ipfs/QmUfzx8X8divpbRA4DS2WN5Nb7vVimqx1R1QgPKLJkNZsb/${tokenId}.png`} width={300} height={300}/> 
              </div>

          )}


        { !minting  && (
              
              <div className="p-5 rounded-md bg-gray-300/70 flex flex-col justify-start  items-start text-black text-sm">
                  <div className="text-md font-bold"> 
                    BLOCKCHAIN DETAILS

                  </div>
                  <div> 
                  <span className="font-bold"> NFT: </span>
                  Bushman Sacrament NFT (BSAC) #{tokenId}

                  </div>
                  <div> 
                    <span className="font-bold"> Token ID:</span> {tokenId} 
                  </div>

                  <div> 
                  <span className="font-bold">Block Number:</span> {blockNumber}
                  </div>

                  <div> 
                  <span className="font-bold">Txn Hash: </span><span className="text-xm">{transactionHash}</span>
                  </div>


               </div>

          )}
      



          

         

         


         { !minting && (
         
         <div className="flex flex-col justify-center items-center">
          
          
          
          {/* <div className="flex flex-row justify-between items-center space-x-3"> 
              <button 
                onClick={() => redirectToOpensea(tokenId) }
                className="mt-4 rounded-lg px-4 py-2 focus:outline-none 
                          bg-green-900 text-gray-200 text-sm
                          hover:bg-gray-500">
                            View on Opensea
                            
              </button>

              <button 
                onClick={() => redirectToBlockScanner(transactionHash)}
                className="mt-4 rounded-lg px-4 py-2 focus:outline-none 
                          bg-green-900 text-gray-200 text-sm
                          hover:bg-gray-500">
                            View on Polyscan        
              </button>
            </div> */}

            <div>
              <button type="submit" 
                  onClick={redirectToHome}
                  className="mt-4 rounded-lg px-4 py-2 focus:outline-none 
                            bg-green-900 text-gray-200 text-sm
                            hover:bg-gray-500">
                              Return to Bushman Sacrament NFT
                              
                </button>
              </div>
          
          </div>
          )

          }



          

        </div>







       </div>
        
    )


}

export default Success

