import React from 'react';
//import { useRouter } from "next/router";
//import Link from "next/link";
import {RiTeamFill, RiDashboardFill, RiSettings2Fill} from 'react-icons/ri';
import {MdSecurity, MdAttachMoney} from 'react-icons/md';
import {FcPicture} from 'react-icons/fc';
import {IoMdClose} from 'react-icons/io';
import {SiEthereum, SiTwitter, SiInstagram, SiDiscord} from 'react-icons/si';
import {GiRoad} from 'react-icons/gi';
import {BsQuestionSquareFill} from 'react-icons/bs';
import {ImUser} from 'react-icons/im';





//import { withProtected } from '../src/hook/route';

//export default function WalletLayout() {
//function NavBtnsHamburger({auth}){
function NavBtnsHamburger({setShowModal}){
    //const router = useRouter()

    const handleClick = (e, path) => {
        e.preventDefault()
        setShowModal(false);
        console.log(path);
        //router.push(path);

        
            document.getElementById(path).scrollIntoView({ behavior: "smooth" })
          
    };





    //const {logout} = auth;

    return (

      
        <div className="flex flex-col  justify-between items-center text-gray-300">

                <div className="flex flex-row justify-end w-[290px] ">
                
                    <div className="bg-gray-800 hover:bg-gray-700  overflow-visible  rounded-full w-10 h-10 ">
                        <IoMdClose 
                            onClick={()=> {setShowModal(false)}}
                            size={25} 
                            className="cursor-pointer translate-x-2 translate-y-2" />
                    </div>

                    
                     

                </div>
            

            <div className="flex flex-col text-sm ">
                <div className="flex flex-row p-2  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    {/* <RiDashboardFill size={20} /> */}
                    <img className="rounded-lg" src={"/logoleaf.png"} width={25} height={25}/> 
                    {/* <Link href="/dashboard"> */}
                    <a  href="https://trifecta.live/" target="_blank" rel="noreferrer">
                        Trifecta Project
                    </a>
                    {/* </Link> */}
                </div>

                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    {/* <ImUser size={20} /> */}
                    <FcPicture  size={20} />
                    {/* <Link href="/profile"> */}
                    <a  onClick={(e) => handleClick(e, "artworkSection")}>
                        Artwork
                    </a>
                    {/* </Link> */}
                </div>

                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    <SiEthereum  size={20} />
                    {/* <Link href="/security"> */}
                    <a  onClick={(e) => handleClick(e, "mintSection")}>
                        Mint
                    </a>
                    {/* </Link> */}
                </div>

                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    {/* <MdAttachMoney  size={20} /> */}
                    <GiRoad  size={20} />
                    {/* <Link href="/manage-balance"> */}
                    
                    <a  onClick={(e) => handleClick(e, "roadmapSection")}>
                    Roadmap
                    </a>
                    {/* </Link> */}
                </div>

                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    {/* <MdAttachMoney  size={20} /> */}
                    <RiTeamFill  size={20} />
                    {/* <Link href="/manage-balance"> */}
                    
                    <a  onClick={(e) => handleClick(e, "teamSection")}>
                    The Team
                    </a>
                    {/* </Link> */}
                </div>

                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    <BsQuestionSquareFill  size={20} />
                    {/* <Link href="/assets"> */}
                    <a  onClick={(e) => handleClick(e, "faqsSection")}>
                    FAQs
                    </a>
                    {/* </Link> */}
                </div>
                
                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    <SiTwitter className="mr-0 hover:text-green-700  hover:opacity-60" size={21} /> 
                    <a target="_blank" href="http://twitter.com/nyahmanbushman?s=11&t=IQoL2-59AqT5pnJIyyUuug">
                        Twitter
                    </a>
                </div>
                    
                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    <SiInstagram className="mr-0 hover:text-green-700  hover:opacity-60" size={21} /> 
                    <a target="_blank" href="https://instagram.com/original_bushman?igshid=YmMyMTA2M2Y=">
                        Instagram
                    </a>
                </div>
                <div className="flex flex-row p-3  w-fit rounded-full hover:bg-gray-800 gap-2 cursor-pointer">
                    <SiDiscord className="mr-0 hover:text-green-700  hover:opacity-60" size={21} /> 
                    <a target="_blank" href="https://discord.gg/AKyWrehkRj">
                        Discord
                    </a>
                </div>






            </div>


        </div>
    );
}

export default NavBtnsHamburger;