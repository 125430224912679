import { getApp } from "firebase/app";
import { signInWithPopup, 
		getAuth, 
		GoogleAuthProvider, 
		signOut, 
		createUserWithEmailAndPassword,
		signInWithEmailAndPassword,
		sendPasswordResetEmail,
		onAuthStateChanged,
		//sendSignInLinkToEmail
	} from "firebase/auth";

class AuthService {

	constructor(firebaseApp) {
		this.auth = getAuth(firebaseApp);

	}

	waitForUser(callback){

		return onAuthStateChanged(this.auth, (userCred) => {
			callback(userCred);

		});	

	}

	// async onAuthStateChanged () {
	// 	try {
	// 		const user =  onAuthStateChanged((user) => { 
	// 			//setUser(user);
	// 			//setIsAuthenticating(false)

	// 			return {
	// 				user: user,
	// 				isAuthenticating: false
	// 			};
	// 		});
		
	// 	}
	// 	catch (e) {

	// 	}

	// }






	



	

	async loginWithGoogle(){
		try {
			const userCred = await signInWithPopup(this.auth, new GoogleAuthProvider());
			return {
				user: userCred.user
			};
		} catch (error) {
			return {
				error: error.message
			};
		}
	
	}

	async makeUserWithEmailAndPassword(email, password){
		
		console.log("called ############## 1");
		let userCred;
		try {
			console.log("before createUserWithEmailAndPassword");
			userCred = await createUserWithEmailAndPassword(this.auth, email, password);

			console.log("heres the credential userCred user")
			console.log(userCred.user);
			
			console.log("after createUserWithEmailAndPassword");
			return {
				userx: userCred.user
			};
		} catch (error) {
			//console.log("error createUserWithEmailAndPassword ", error );
			//console.log("error in AuthService: " + error);

			//const indx = error.indexOf("email-already-in-use");
			//console.log("indexOf: ");
			//console.log("includes: " + error.includes("email-already-in-use", 0 ) );

			//if( error.includes("email-already-in-use", 0 ) )
			//{

				
			//}



			return {
				error: error.message //error
			};
		}
	

		
	}


	// async sendSignInLinkToEmail(email){
		
	// 	console.log("called ############## 1: ", email);

	// 	try {
	// 		console.log("before");
	// 		const userCred =  sendSignInLinkToEmail(this.auth, email, {
	// 			url: "http://localhost:3000/confirm",
	// 			handleCodeInApp: true,
	// 		})

	// 		console.log("heres user")
	// 		console.log(userCred);
			
	// 		console.log("after ");
	// 		return {
	// 			userx: userCred.user
	// 		};
	// 	} catch (error) {
	// 		//console.log("error createUserWithEmailAndPassword ", error );
	// 		return {
	// 			error: error.message
	// 		};
	// 	}
	

		
	// }

	

	async loginWithiPing(email, password){
		
		try {
			const userCred = await signInWithEmailAndPassword(this.auth, email, password);
			return {
				user: userCred.user
			};
		} catch (error) {
			return {
				error: error.message
			};
		}
	
	}

	async forgotPassword(email){
		
		try {
			const data = await sendPasswordResetEmail(this.auth, email, { url: 'http://localhost:3000/login', });
			return {
				user: data
			};
		} catch (error) {
			console.log(error.message);
			return {
				error: error.message
			};
		}
	
	}


	 
	async logout() {
		await signOut(this.auth);
	}
}

export default new AuthService(getApp());
//export default new AuthService();


