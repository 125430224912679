import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useState, useEffect, useRef } from 'react';
import { BigNumber, ethers, BigInt } from "ethers";
import { abi } from "../abi"
import { onSnapshot, doc, serverTimestamp, updateDoc, query, collection, where } from "@firebase/firestore";
import { db } from "../config/firebase.config";

import { useQuery } from 'urql';
import { useNav } from "../hooks/useNav"

import { ModalTransfer } from "../components/modal-transfer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ModalHamburger } from "./modal-hamburger";

import { Web3AccountContext } from '../context/Web3AccountContext';



//import { createClient, ssrExchange, dedupExchange, cacheExchange, fetchExchange, useQuery } from 'urql';

//import { Provider } from "urql";
//import { client, ssrCache } from "../urqlClient";



//server-side will be used with NextJS
//const isServerSide = typeof window === "undefined";
//const ssrCache = ssrExchange({isClient: !isServerSide});

// const client = createClient ({
//   url: "https://graphql-weather-api.herokuapp.com/",
//   exchanges: [dedupExchange, cacheExchange, ssrCache, fetchExchange],

//   //headers left empty {} and not need for now but used when we need to pass credentials in headers
//   fetchOptions: () => {
//     return { headers: {} };
//   },


// });

// const client = createClient ({
//   url: "https://api.thegraph.com/subgraphs/name/ipingcode/sacramentapi",
//   exchanges: [dedupExchange, cacheExchange, ssrCache, fetchExchange],

//   //headers left empty {} and not need for now but used when we need to pass credentials in headers
//   fetchOptions: () => {
//     return { headers: {} };
//   },


// });


const WeatherQuery = `
  query TorontoWeather($city: String!) {
    getCityByName(name: $city) {
      id
      name
      weather {
        summary {
          title
          description
        }
        temperature {
          min
          max
          actual
        }
      }
    }
  }
`

const tokensQuery = `
  query {
    tokens  {
      id
      tokenID
      tokenURI
      externalURL
      tokenOwner { id }
    }
  }
`

// const usersQuery = `
// query {
//   users(first: 30) {
//     id
//     tokens {
//       id
//     }
//   }
// }
// `


//users(where: {id_in: $user }) {

const usersQuery = `
query($user: [String]) {
  users( where: {id_in: $user }) {
    id 
    tokens (first: 100) {
      id
    }
  }
}
`





// const tokensQuery = `
//   query {
//     tokens(
//       where: { id_in: ["21", "101"]}) {
//       id
//       tokenID
//       tokenURI
//       externalURL
//     }
//   }
// `

const redirectToOpensea = async (tokenId) => {

  //window.open(`https://testnets.opensea.io/assets/mumbai/0x9c55ed957e525503173e21045bfa160b6fb2088e/${tokenId}`, '_blank');
  window.open(`https://opensea.io/assets/matic/0xd8501a6458eb8a1b511571b174e4576df132ff0c/${tokenId}`, '_blank');
  
};



const ListTokens = () => {
  const listingRef = useNav("Listing")
  const { ref, inView } = useInView({ threshold: 0.2 });
  const animation = useAnimation();
  const [showModalTransfer, setShowModalTransfer] = useState(false);
  const [modalMessage, setModalMessage] = useState(true)

  const [userData, setUserData] = useState()
  const [userTokens, setUserTokens] = useState()

  // useEffect(() => {
  //   //const getUserInfo =  (userId) => {

  //   onSnapshot(doc(db, "users", "ZTYYejHgamcK7h4xjVnqHBGQt0M2"), (doc) => {

  //     console.log("we got the data");

  //     setUserData(doc.data());
  //     console.log(doc.data());
  //   })

  // }, []);


  // useEffect(() => {
  //   //const getUserInfo =  (userId) => {

   
  //   onSnapshot(doc(db, "tokens", "ZTYYejHgamcK7h4xjVnqHBGQt0M2"), (doc) => {

  //     console.log("we got the data");

  //     setUserTokens(doc.data());
  //     console.log(doc.data());
  //   })

  // }, []);

  const params = useParams();
  const userId = params.id;

  useEffect(() => {
  
    const q = query(collection(db, "tokens"), where("userId", "==", userId)); //raAByCD9odasOWFSOBw5Ra023Jm1 //ZTYYejHgamcK7h4xjVnqHBGQt0M2

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tokens = [];
      querySnapshot.forEach((doc) => {
          tokens.push(doc.data().tokenId);
          //tokens.push({ "id": "110" });
      });
      console.log("User has the follwing tokens ", tokens.join(", "));

      
      const myarray = [ "1", "2", "3", "4", "5"];

      const mappedData = tokens.map( (num) => {

        return { id: num }

      })

      setUserTokens(mappedData);
      console.log(JSON.stringify(mappedData));
      //console.log(tokens)

      //setUserTokens(tokens);


    });
    
  

  }, []);


  



  const { userAccount, setUserAccount, connectWeb3 } = useContext(Web3AccountContext);


  const connect2 = async () => {


    var win = window.open("https://metamask.app.link/dapp/sacrament.live/list-tokens", '_blank');
    //var win = window.open("https://nft-minter-tau.vercel.app", '_blank');
    win.focus();

  }



  // const [result] = useQuery({
  //   query: WeatherQuery,
  //   variables: { city: "Toronto" },
  // })

  // const [result] = useQuery({
  //   query: tokensQuery,
  //   variables: { userTokens: [21, 98] },
  // })

  // const [result] = useQuery({
  //   query: tokensQuery,
  //   variables: { id: 22 },
  // })

  const [result] = useQuery({
    query: usersQuery,
    variables: { user: [userAccount.toString()] },
  })


  //console.log(userAccount)

  //0x5124e280aB8cC6AaCeD31028451d8c94a405F656
  //variables: { user: ["0xde89332be5d3188ff209253344a1b64fff87a1d9"]},   //0x3907d7c8ab29198ee26629771c229c4d56fb66be

  // const [result] = useQuery({
  //   query: tokensQuery,
  //   variables: { user: ["0x10754e4f37a139bf3ed1dbb778b4e2121eb9689f", "0x116fcd3f7bfffa876b40ffae5982a06845e22bd7"]},
  // })


  //0x10754e4f37a139bf3ed1dbb778b4e2121eb9689f
  //0x116fcd3f7bfffa876b40ffae5982a06845e22bd7
  //0x3907d7c8ab29198ee26629771c229c4d56fb66be


  const { data, loading, error } = result;

  if (loading) return <div> Loading ...</div>;
  if (error) return <div> Error, oops! </div>;



let tokens = data?.users[0]?.tokens;
tokens = userTokens;







  //bg-[url('../public/bgimageart.png')]
  return (
    <section className="p-5 relative bg-gray-900   bg-fixed bg-center  bg-no-repeat bg-cover text-black px-4 " ref={listingRef} id="listingSection">


      {/* <pre>

      {JSON.stringify(data?.users[0].tokens, null, 2)}
      </pre>  */}

      {/* <div className="text-white">{userAccount}</div> */}

      {
        tokens == undefined &&

        <div className="text-white">No NFTs: {userId} </div>

        

      }

      {
        userAccount == "" &&
        <div className=" bg-green-900  rounded-md p-2 ">
          <button className="" onClick={connectWeb3}>
            Connect
          </button>
        </div>

      }

{
        userAccount == "" &&
        <div className=" bg-green-900  rounded-md p-2 ">
          <button className="" onClick={connect2}>
            Connect 2
          </button>
        </div>

      }


      {

    tokens != undefined &&



        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-1 p-2  rounded-lg bg-gray-100">

          {



          tokens?.map((item, i) => {






              return (

                <div key={i}>


                  <div className="p-2">

                    <div className="group bg-white  shadow-gray-300 shadow-md rounded-lg overflow-hidden ">

                      <img className="transition ease-in-out  duration-500  cursor-pointer  hover:scale-110 rounded-t-lg" src={`https://sacrament.mypinata.cloud/ipfs/QmUfzx8X8divpbRA4DS2WN5Nb7vVimqx1R1QgPKLJkNZsb/${item.id}.png`} />

                      <div className="z-10  bg-white p-2  ">

                        <div className="text-xs font-bold ">
                          Bushman Sacrament NFT #{item.id}
                        </div>





                      </div>

                      <div className="transition ease-in-out duration-500 translate-y-14 h-[50px] group-hover:translate-y-0 text-white font-bold bg-green-700  flex flex-row justify-center cursor-pointer">
                        {/* <span className="pt-2 cursor-pointer">See Details</span> */}



                        <div className="flex flex-row justify-between p-2 items-center space-x-3 -translate-y-2">
                          <button
                            onClick={() => redirectToOpensea(item.id)}
                            className="mt-4 rounded-lg px-4 py-1 focus:outline-none 
                                  bg-green-900 text-gray-200 text-xs lg:text-sm 
                                  hover:bg-gray-500">
                            View on Opensea

                          </button>

                          <button
                            onClick={() => { setShowModalTransfer(true) }}
                            className="mt-4 rounded-lg px-4 py-1 focus:outline-none 
                                  bg-green-900 text-gray-200 text-xs lg:text-sm 
                                  hover:bg-gray-500">
                            Transfer to My Wallet
                          </button>
                        </div>










                      </div>


                    </div>

                  </div>

                </div>


              )

            })}

        </div>

      }



      <ModalTransfer className="" showModal={showModalTransfer} setShowModal={setShowModalTransfer} modalMessage={modalMessage} />


    </section>

  )


}

//export { client, ssrCache }
export default ListTokens

