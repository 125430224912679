import { useEffect, useState, useContext } from "react"
import InputField from "./input-field"
import SelectField from "./select-field";
import TextareaField from "./textarea-field";
//import { ChevronRightIcon } from '@heroicons/react/solid'
import emailjs from 'emailjs-com';

import { addDoc, collection, serverTimestamp, updateDoc, doc, setDoc } from "@firebase/firestore";
//import AuthService from "../service/AuthService";
import { db } from "../config/firebase.config";

import useAuth from '../hook/auth';

const { uniqueNamesGenerator, NumberDictionary, adjectives, colors, animals } = require('unique-names-generator');
//Bushman Sacrament NFT (BSN)
    // contractAddress: "0x9C55ED957e525503173e21045BFA160B6Fb2088e",
    // marketplaceIdentifier: 'bushman-sacrament-nft',




const TransferForm = ({value}) => {

  const { user,  makeUserWithEmailAndPassword, setUser, error} = useAuth();
  //const { user } = useContext(AuthContext);

  //const makeUserWithEmailAndPassword = useAuth();













  

  
  const [values, setValues] = useState({
    email: '',
    product: 'Bushman Sacrament NFT',
    intro: 'Thanks for choosing ',
    support: 'Sacrament Support Team',
    id: ''
  });
  
  const [ message, setMessage ] = useState(''); //
  const [ status, setStatus ] = useState('');
  //const [ errorMessage, setErrorMessage ] = useState("");
  
  
  //const sentMessage = "A verification link was sent to your inbox.";

 



  const validateEmail = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(values.email)) {
      setMessage(""); //A verification link was sent to your inbox.
    } else if (!regEx.test(values.email) && values.email !== "") {
      setMessage("Email is Not Valid");
      return;
    } else {
      setMessage("");
    }

  };

     const sendEmail = async (userId) => {

      values.id = userId;
      console.log("values id: ", values.id)
      console.log("values email: ", values.email)

      await emailjs.send('service_gbhj2ao', 'template_z38ix7e', values, 't3uarFw-hOHHUnEe5')
      .then(response => {
        console.log('SUCCESS!', response);
        console.log("myvalues: ", values);
        setValues({
          product: '',
          intro: '',
          email: '', //'
          support: '',
          id: ''
         
        });
        setStatus('SUCCESS');
      }, error => {
        console.log('FAILED...', error);
      });



     };

   


    useEffect(() => {
      if(status === 'SUCCESS') {
        setTimeout(() => {
          setStatus('');
        }, 3000);
      }
    }, [status]);

     useEffect(() => {
      

      if (user !== '')
      {
        console.log("user changed ", user);
        saveUser(user);
        
        // setValues(values => {
        // return {
        // ...values,
        //   id: user
        // }
        // });

        // setValues(values => {
        //   return {
        //   ...values,
        //     id: user
        //   }
        //   });

          // setValues(values => ({
          //   ...values,
          //   id: user
          // }))

        //handleChangeId(user);
        

        //if (values.id !== '')
        //{

          //console.log("sending email: ", values.id);
          //sendEmail();
        //}

        

        //console.log("values");
        //console.log(values);

       


      }
      
    }, [user]);

    // useEffect(() => {
      

    //   if (error !== '')
    //   {
    //     console.log("error changed ", error);
    //     setMessage(error)
    //   }
      
    // }, [error]);

   

  
    const handleChange = (e) => {
      //console.log("values before: ", values)
      setValues(values => ({
        ...values,
        [e.target.name]: e.target.value
      }))

      // setValues(values => {
      //   return {
      //   ...values,
      //   id: user
      // }
      // });

      //console.log("values after: ", values)
    }

    const handleChangeId = (id) => {
      //console.log("values before: ", values)
      setValues(values => ({
        ...values,
        ["id"]: id
      }))

    }
    

  const handleSubmit = async (e) => {
    e.preventDefault();


    validateEmail();
   
    try {

      await makeUserWithEmailAndPassword(values.email, "1234567");
      // console.log("position 1")
      // await sendSignInLinkToEmailx(values.email);
      // console.log("position 2")

      setMessage("A verification link was sent to your inbox."); //
    
    } catch (e) {

      //console.log("we have an error: ", e.message);

      //setErrorMessage("error");

      //setMessage("Error: ", error);
      
    // //console.log("are we in the error phase",  e);
    // if ( e.message.includes("email-already-in-use") )
    // {
    //   //setErrorMessage("email-already-in-use");
    //   setMessage("Email was already verified. Please login to continue");
      
    // }

    setMessage("Email was already verified. Please login to continue");

   

    //setMessage("Email was already verified. Please login to continue");



      //setMessage("error: ", e)
      //console.log("error: ", e)
    }

    //sendTransaction();
   
    }

    const getRandomName = () => {
      const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
     // const randomUserName = 
      
      return uniqueNamesGenerator({
          dictionaries: [adjectives, animals, numberDictionary], // colors can be omitted here as not used
          length: 3
        }); 
    }

    const saveUser = async (userId) => {

      handleChangeId(userId);
      console.log("user: ", userId);

      await setDoc(doc(db, "users", userId), {
       
        id: userId,
        username: getRandomName(), 
        firstName: '', 
        lastName: '', 
        email: values.email, 
        emailValidated: false, 
        password: '', 
        accountBalance: 0, 
        timestamp: serverTimestamp() }
        );

        

        //console.log("sending email: ", values.id);
        sendEmail(userId);


    }


   



   




  

const buttonCss = "mt-4  rounded px-4 py-2 focus:outline-none"
const buttonDisabled = "bg-gray-200 text-gray-400 font-light " + buttonCss;
const buttonEnabled = "bg-gray-900 text-gray-200 hover:bg-gray-700 "  + buttonCss;
console.log("what is the user uid", user);

  return (
    <div className="bg-gray-300 rounded -mt-[15px] p-5 w-[400px]">
      {/* {status && renderAlert()} */}
      <form onSubmit={handleSubmit}>
        <h3 className="text-gray-700 mb-3 text-xl font-semibold">Verify your Email</h3>
        {/* <InputField value={values.fullName} handleChange={handleChange} label="Full Name" name="fullName" type="text" placeholder="John Doe" /> */}
        <InputField className="text-black" value={values.email} handleChange={handleChange} label="E-Mail" name="email" type="email"  />
        {/* <SelectField handleChange={handleChange} name="role" label="Role" /> */}
        {/* <TextareaField value={values.message} handleChange={handleChange} label="Your message here" name="message" /> */}
        <button type="submit" disabled={ values.email.length < 1 }
          className={ values.email.length < 1? buttonDisabled : buttonEnabled }
        >Continue {user} 
        {/* <ChevronRightIcon className="w-6 ml-2 float-right" /> */}
        </button>
      </form>
      <div className="flex justify-center  items-center   text-black font-light mt-3">
      {message}
      </div>
    </div>
  )
}

const renderAlert = () => (
  <div className="px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded mb-5 text-center">
    <p>Your verification email was submitted successfully</p>
  </div>
)

export default TransferForm

//handle error snippets
// // let  msg ="";

      // if (userCred.error == undefined){
      //   userCred.error = "";
      // }

      // if (userCred.error !== "")
      // {
      //   if (userCred.error.includes("email-already-in-use")) {
      //      msg = `The email ${values.email} is already in use.`
      //   } else {
      //     msg = "Error: " + userCred.error
      //   }
      // }




      /// setValues snippet

       // .then(() => {

      //   setValues(values => {
      //       return {
      //       ...values,
      //       id: '7777777'
      //     }
      //     });

      // });

       // setValues(values => {
      //   return {
      //   ...values,
      //   id: userId
      // }
      // });