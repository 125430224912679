import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  //Dont' use StrictMode because of this React Hooks: useEffect() is called twice even if an empty array is used as an argument
  //https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  // <React.StrictMode>
    <App />
  //</React.StrictMode>
);

