import { useEffect, useState } from "react";
import { useNav } from "../hooks/useNav"
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion"; 
import { useScroll } from "../components/useScroll";
import { ModalMintWithCC } from "../components/modal-mint-with-cc";

import Web3Modal from "web3modal";
import {ethers} from "ethers";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import {BsFillCreditCard2BackFill} from 'react-icons/bs';


//import {CoinbaseWalletSDK} from "@coinbase/wallet-sdk";
//import WalletConnectProvider from "@walletconnect/web3-provider";


//https://ethereum.stackexchange.com/questions/117025/how-do-i-connect-to-metamask-from-web-app-on-mobile-devices
//https://ethereum.stackexchange.com/questions/103017/trying-to-get-working-wallectconnect-web3modal-on-safari-ios
//https://walletconnect.com/ 

//How to Connect MetaMask Wallet to Website on Phone
//https://www.youtube.com/watch?v=8BL7COrxJvg


 




const Mint = () => {
  const mintRef = useNav("Mint")
  const [element, controls] = useScroll();

  const {ref, inView } = useInView({ threshold: 0.2});
  const animation = useAnimation();
  const [showModalMintWithCC, setShowModalMintWithCC] = useState(false);
  const [hasMetaMask, setHasMetaMask] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [web3Provider, setWeb3Provider] = useState(null)


  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "65a16ffd9e7b4e0480430d6064b9901f",
      },
    }
  
   }


  async function connectWallet() {

    try {
      let web3Modal = new Web3Modal(  {
        network: "mainnet",
        cacheProvider: true,
        providerOptions,

      });

     


      const web3ModalInstance = await web3Modal.connect();
      const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
      //console.log(web3ModalProvider);
      if (web3ModalProvider) {

        setWeb3Provider(web3ModalProvider);

      }

    } catch(error){

      console.error(error);


    }
  
  
  }
  
  
  // useEffect(() => {

  //   if (window.ethereum )
  //   {
  //     handleEthereum();
  //     //setHasMetaMask(true)
  //     //console.log("window.ethereum: true");
  //   }
  //   else{
      
  //     window.addEventListener('ethereum#initialized', handleEthereum, {
  //       once: true,
  //     });

  //     // If the event is not dispatched by the end of the timeout,
  //     // the user probably doesn't have MetaMask installed.
  //     setTimeout(handleEthereum, 3000); // 3 seconds

  //     //console.log("window.ethereum: false");

  //   }

    

  

    
  //   function handleEthereum() {
  //     const { ethereum } = window;
  //     if (ethereum && ethereum.isMetaMask) {
  //       console.log('Ethereum successfully detected!');
  //       setHasMetaMask(true)
  //       // Access the decentralized web!
  //     } else {
  //       console.log('Please install MetaMask!');
  //     }
  //   }


  //   const handleEvent =  (e) => 
  //   { 
  //       try { 
  //       const data = JSON.parse(e.data);
  //       console.log("channel: " + data.channel);
  //       setShowModalMintWithCC(true);

  //       //if (data.channel.includes('close'))
        
  //       }
  //       catch (e) {
  //         setShowModalMintWithCC(false);
  //       }
  //   }


  //   window.addEventListener('message',handleEvent);
  //   return () => {
  //     window.removeEventListener('message', handleEvent);
  //   }
  // });


  useEffect (() => {

    if(inView) {

      animation.start({
        opacity: 1,
        transition: {
          type: 'spring', duration: 5, bounce: 0.3
        }
      });

    }

    if(!inView) {
      animation.start({opacity: 0});
    }



    


    //console.log("user effect hook, inView = ", inView)
    
  }, [inView]);

  

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()

    

  })


  const handleResize = () => {
   

    if (window.innerWidth < 1050) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const openMinter = () => {
    var url = "https://nft-minter-tau.vercel.app/";
    var win = window.open(url, '_blank');
    win.focus();

}

const connect2 = async () => {
       
  
  var win = window.open("https://metamask.app.link/dapp/nft-minter-tau.vercel.app", '_blank');
  //var win = window.open("https://nft-minter-tau.vercel.app", '_blank');
  win.focus();

}
 


  return (
    //<section ref={mintRef} id="mintSection" className=" bg-gradient-to-b from-[#222] to-[#30642f] px-4">
    
    <section ref={mintRef} id="mintSection" className="h-full w-full bg-gray-900 px-4">
     


<div  ref={ref} className="text-white">
     
      <motion.div animate={animation} className="w-full ">
        <motion.div animate={animation} className="sectionHeader p-6 w-[700px] " >MINT </motion.div>
{        

<>
  {/* <div> isMobile: {isMobile.toString()}</div>
  <div> hasMetaMask: {hasMetaMask.toString()}</div> */}


  
  </>


        /*{ !showModalMintWithCC &&  (
         <iframe  src="https://nft-minter-tau.vercel.app/" className="relative -mt-4 w-screen h-[650px]"/>
        )} */}

        

        {/* { showModalMintWithCC &&  (
            <ModalMintWithCC className="absolute -mt-[200px] w-screen h-[651px]" showModal={showModalMintWithCC} setShowModal={setShowModalMintWithCC} />
        )} */}

        {/* { (hasMetaMask && !isMobile) && (
          <iframe  src="https://nft-minter-tau.vercel.app/" className="relative -mt-4 w-screen h-[650px]"/>
        )} */}


      { ( !isMobile) && (
           <div className="flex justify-evenly items-center"> 
           <div className="w-[320px] flex justify-evenly items-center gap-4 bg-gray-500/50  rounded-2xl p-3 text-black">
             <button onClick={openMinter} className="p-2 h-12 w-72 flex justify-center items-center gap-1 rounded-lg bg-gray-200 hover:bg-green-500 border-none hover:text-gray-200" >
               <img src="/metamasklogo.png" className="w-7 h-7" alt="MetaMask " /> 
               Mint with MetaMask Wallet 
             </button> 
           </div>
         </div>
        )}

        {/* { (hasMetaMask && isMobile) && ( */}
        { (isMobile) && (
          
          <div className="flex justify-evenly items-center"> 
            <div className="w-[320px] flex justify-evenly items-center gap-4 bg-gray-500/50  rounded-2xl p-3 text-black">
              <button onClick={connect2} className="p-2 h-12 w-72 flex justify-center items-center gap-1 rounded-lg bg-gray-200 hover:bg-green-500 border-none hover:text-gray-200" >
                <img src="/metamasklogo.png" className="w-7 h-7" alt="MetaMask " /> 
                Mint with MetaMask Wallet 
              </button> 
            </div>
          </div>


        )}

        {/* { !hasMetaMask && (

          
          <ModalMintWithCC className="absolute -mt-[200px] w-screen h-[651px]" showModal={showModalMintWithCC} setShowModal={setShowModalMintWithCC} />
        )} */}

        { !showModalMintWithCC && (
                <div className="m-3 text-lg font-bold flex justify-evenly items-center">OR</div>
          )
        }
        { !showModalMintWithCC && (
            <div className="flex justify-evenly items-center"> 
              <div className="w-[320px] flex justify-evenly items-center gap-4 bg-gray-500/50 rounded-2xl p-3 text-black">
                <button onClick={() => {setShowModalMintWithCC(true)}} className="p-2 h-12 w-72 flex justify-center items-center gap-1 rounded-lg bg-gray-200 hover:bg-green-500 border-none hover:text-gray-200" >
                  
                  
                

                  <div className="flex  justify-center items-center  gap-2 w-full p-2">

                  <BsFillCreditCard2BackFill size={25}  />
                    <div className="">Mint with Credit Card</div>
                    {/* <img className="" src={"/ccs.png"} width={160}/>  */}
                    
                  </div>
                
                </button> 
              </div>
            </div>
            )

        }


        <ModalMintWithCC className="absolute -mt-[200px] w-screen h-[651px]" showModal={showModalMintWithCC} setShowModal={setShowModalMintWithCC} />
        



         {/* https://nft-minter-kml7ec58o-ipingcode.vercel.app     https://nft-minter-tau.vercel.app/  http://localhost:8080/"*/}
         
      </motion.div>

      <div className="h-[150px]"></div>
     
     </div>

     

    
{/* <motion.div animate={animation2} className="content -mt-48 text-6xl font-extrabold">ROADMAP</motion.div> */}
      

    </section>
   
  )
}

export default Mint