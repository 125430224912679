import { Variants } from "framer-motion";
//https://create-react-app.dev/docs/adding-typescript/
export const fadeIn = (direction:"up"|"down" = "up"): Variants => {
    return {
    initial: {
        y: direction === "up" ? 40 : -40,
        opacity: 0,
    },  

    animate: {
        y: 0,
        opacity: 1,

        transition: {
            duration: 1.0,
            ease: "easeInOut",
        },

    },
}
}

export const fadeInHorizontal = (direction:"left"|"right" = "right"): Variants => {
    return {
    initial: {
        x: direction === "left" ? 40 : -40,
        opacity: 0,
    },  

    animate: {
        x: 0,
        opacity: 1,

        transition: {
            duration: 1.0,
            ease: "easeInOut",
        },

    },
}
}


export const leafWrapper: Variants = {
   
    initial: {
        y: -1000,
        
    },  

    animate: {
        y: 0,
       

        transition: {
            delay: 1.2,
            duration: 1.2,
            type: "spring",
        },

    },

}

export const leaf: Variants = {

    initial: {},
    animate: {
        y: [5, 0, 5],
        transition: {
            duration: 2.6,
            ease: "linear",
            repeat: Infinity,
        },

    },


    }

    export const collage: Variants = {

        initial: {},
        animate: {
            y: [5,0,5],
            transition: {
                duration: 2.6,
                ease: "linear",
                repeat: Infinity,
            },
    
        },
    
    
        }

        export const artwork: Variants = {

            initial: {},
            animate: {
                y: [5,0,5],
                transition: {
                    duration: 2.6,
                    ease: "linear",
                    repeat: Infinity,
                },
        
            },
        
        
            }



export const staggerContainer: Variants = {

    initial: {},
    animate: {
        transition: {
            staggerChildren: 0.5,
            delayChildren: 0.5
        },

    },


}





export const videoAnimation = {
    hidden: { x: -800, opacity: 0 },
    show: { x: 0, opacity: 1 },
  };

  export const reasonsAnimation = {
    hidden: { x: 800, opacity: 0 },
    show: { x: 0, opacity: 1 },
  };
  





// export const fadeInDown: Variants = {

//     initial: {
//         y: -40,
//         opacity: 0,
//     },

//     animate: {
//         y: 0,
//         opacity: 1,

//         transition: {
//             duration: 1.0,
//             ease: "easeInOut",
//         },

//     },


// }