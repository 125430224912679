import { useEffect } from "react";
import { useNav } from "../hooks/useNav"
import { useScroll } from "../components/useScroll";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion"; //https://www.youtube.com/watch?v=-1ihhsv88O0&list=PLQKg8mIgoxKqTBBPf1cbVg20EceDUlcsm
import { videoAnimation, reasonsAnimation } from "../variants.tsx";
import Accordion from "../components/accordion";

import {BsLinkedin} from 'react-icons/bs';



 

const Team = () => {
  const teamRef = useNav("Team")
  const [element, controls] = useScroll();

  const {ref, inView } = useInView({ threshold: 0.2});
  const animation = useAnimation();
  const animation2 = useAnimation();

  // const updateAccordion = async ({id}) => {
  //   console.log("updating accordion" + id);
  // };








  useEffect (() => {

    
    console.log(process.env)

    if(inView) {

      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.3
        }
      });

      animation2.start({
        x: 0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.3
        }
      });

    }

    if(!inView) {

      animation.start({x: '-100vw'});
      animation2.start({x: '100vw'});
    }

    //console.log("user effect hook, inView = ", inView)
    
  }, [inView]);





  return (
    <section ref={teamRef}  id="teamSection" className="h-[600px] md:h-[600px] bg-gradient-to-b  from-[#222] to-[#000] px-4  overflow-visible">

<div  ref={ref} className="text-white overflow-visible">
     
      
        <motion.div animate={animation2} className="sectionHeader mt-2 md:mt-24">THE TEAM</motion.div>


        <div className=" grid grid-cols-2 md:flex justify-evenly items-center mt-12 gap-0 profileText">
        

         <div className="flex flex-col justify-center items-center gap-2 mb-10">
            <div className="flex justify-center items-center relative z-0"> 
              <img className="rounded-full z-10 opacity-0 hover:opacity-100 profilePicSize profilePicHoverTranslate " src={"/cr-07488.png"}/> 
              <img className="rounded-full   profilePicSize  profilePicTranslate  "   src={"/dshep_sphere.png"} />
            </div>
            <div className="font-extrabold">DSHEP</div>
            <div className="">Tech Lead</div>
            <div className="mt-2"><a href="https://www.linkedin.com/in/darryl-shepherd-81a55632" target="_blank"><BsLinkedin  size={30} /></a></div>
          </div>

          

          <div className="flex flex-col justify-center items-center gap-2 mb-10">
            <div className="flex justify-center items-center relative z-0"> 
              <img className="rounded-full z-10 opacity-0 hover:opacity-100 profilePicSize profilePicHoverTranslate " src={"/cr-02130.png"}/> 
              <img className="rounded-full   profilePicSize  profilePicTranslate  "   src={"/dz_sphere.png"} />
            </div>
            <div className="font-extrabold">DASEZ</div>
            <div className="">Creative Director</div>
            <div className="mt-2"><a href="https://www.linkedin.com/in/dasez-scott-a2345118" target="_blank"><BsLinkedin  size={30} /></a></div>
          </div>


          <div className="flex flex-col justify-center items-center gap-2 mb-10">
            <div className="flex justify-center items-center relative z-0"> 
              <img className="rounded-full z-10 opacity-0 hover:opacity-100 profilePicSize profilePicHoverTranslate " src={"/cr-07341.png"}/> 
              <img className="rounded-full   profilePicSize  profilePicTranslate  "   src={"/kinjal_sphere.png"} />
            </div>
            <div className="font-extrabold">KINJAL</div>
            <div className="">Graphic Artist</div>
            <div className="mt-2"><a href="https://www.linkedin.com/in/inkstasy" target="_blank"><BsLinkedin  size={30} /></a></div>
          </div>

          <div className="flex flex-col justify-center items-center gap-2 mb-10">
            <div className="flex justify-center items-center relative z-0"> 
              <img className="rounded-full z-10 opacity-0 hover:opacity-100 profilePicSize profilePicHoverTranslate " src={"/cr-05080.png"}/> 
              <img className="rounded-full   profilePicSize  profilePicTranslate  "   src={"/ms_sphere.png"} />
            </div>
            <div className="font-extrabold">MSBLOCKCHAIN</div>
            <div className="">Big Baller</div>
            <div className="mt-2"><a href="https://www.linkedin.com/in/vanessa-duncan-bb93ab26" target="_blank"><BsLinkedin  size={30} /></a></div>
          </div>
          


        </div>
      





     
     </div>

    
{/* <motion.div animate={animation2} className="content -mt-48 text-6xl font-extrabold">ROADMAP</motion.div> */}
      

    </section>
  )
}

export default Team