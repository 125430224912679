import { useState, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';










const NotFound = () => {
  
    const navigate = useNavigate()

    useEffect(() => {
      
        setTimeout(() => {
            navigate("/")
        }, 1000)
    
    }, [navigate]);


    return (
       <div>

           Not found 
           {/* <Navigate to="/" /> */}
       </div>
        
    )


}

export default NotFound

