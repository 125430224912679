import {useState} from 'react';
//import Image from "next/image"
//import Link from "next/link";

import {RiDashboardFill, RiSettings2Fill} from 'react-icons/ri';
import {IoGrid} from 'react-icons/io5';


import Collage from "../components/collage";

import { collage } from "../variants.tsx";

import { useNav } from "../hooks/useNav"
import { motion, useAnimation } from "framer-motion"; 
import { useInView } from "react-intersection-observer";
import { useScroll } from "../components/useScroll";



import { fadeIn, fadeInHorizontal} from "../variants.tsx";
import { SiDiscord, SiTwitter, SiInstagram} from 'react-icons/si';



//export default function WalletLayout() {
    export default function Footer(){
    //const {logout} = auth;

    const footerRef = useNav("Footer")
  const [element, controls] = useScroll();

  const {ref, inView } = useInView({ threshold: 0.2});
  const animation = useAnimation();
  const animation2 = useAnimation();

   
      
    
   
   

    return (


      // -translate-y-[150px] 

        // <footer className="border-t border-t-gray-600 bg-black">
        //bg-gradient-to-b  from-[#222] to-[#30642f] 

<section ref={footerRef} id="footerSection" className="h-[300px] flex flex-col justify-evenly items-center text-white bg-gradient-to-b  from-[#222] to-[#000] px-4">
        

<motion.div 
            variants={fadeInHorizontal("left")}  
            initial="initial"
            animate="animate"
            className="flex gap-2">

            <a target="_blank" href="http://twitter.com/nyahmanbushman?s=11&t=IQoL2-59AqT5pnJIyyUuug">
                <SiTwitter className="mr-2 hover:text-green-700  hover:opacity-60" size={21} /> 
            </a>
            <a target="_blank" href="https://instagram.com/original_bushman?igshid=YmMyMTA2M2Y=">
                <SiInstagram className="mr-2 hover:text-green-700  hover:opacity-60" size={21} /> 
            </a>

            <a target="_blank" href="https://discord.gg/AKyWrehkRj">
                <SiDiscord className="mr-2 hover:text-green-700  hover:opacity-60" size={21} /> 
            </a>
            
            </motion.div>


            

          <div className="-mt-10 flex justify-center items-center md:gap-4  md:w-[500px] ">
            
          
            
            <div className="hover:bg-gray-800 p-2 rounded">
              <a target="_blank" href="https://www.dasezcreative.com">
                <img src={"/dasezcreativelogo.png"} alt="Dasez Creative Logo"
                  width={150}
                  height={75}

                  loading="lazy"
                  className='cursor-pointer '
                />
              </a>

            </div>


            {/* <div>
              <a className="hover:text-gray-300 text-gray-100 hover:underline  text-sm " target="_blank" href="https://www.dasezcreative.com" >Fueled by Dasez Creative </a>
            </div> */}
            {/* <div></div> */}

            <div className="hover:bg-gray-800 p-2 rounded">
              <a target="_blank" href="https://www.iping.com">
                <img src={"/ipinglogowhite.png"} alt="iPing Labs Logo"
                  width={85}
                  height={42.5}

                  loading="lazy"
                  className='cursor-pointer '
                />
              </a>

            </div>


            <div className="hover:bg-gray-800 p-2 rounded">
              <a target="_blank" href="https://opensea.io/collection/bushman-sacrament">
                <img src={"/opensealogo.png"} alt="Opensea Logo"
                  width={65}
                  

                  loading="lazy"
                  className='cursor-pointer '
                />
              </a>

            </div>


            <div className="hover:bg-gray-800 p-2 ">
              <a target="_blank" href="https://etherscan.io/">
                <img src={"/ethereumlogo.png"} alt="Ethereum Logo"
                  width={50}
                  height={50}

                  loading="lazy"
                  className='cursor-pointer rounded-full'
                />
              </a>

            </div>

            <div className="hover:bg-gray-800 p-2 ">
              <a target="_blank" href="https://polygon.technology">
                <img src={"/polygonlogo.png"} alt="Polygon Logo"
                  width={80}
                  

                  loading="lazy"
                  className='cursor-pointer rounded-full'
                />
              </a>

            </div>


            <div className="hover:bg-gray-800 p-2 bg-white rounded-full">
              <a target="_blank" href="https://canexjamaica.com/">
                <img src={"/canex.logo.png"} alt="CanEx Logo"
                  width={67}
                  

                  loading="lazy"
                  className='cursor-pointer rounded-full'
                />
              </a>

            </div>

            



          </div>

          
        

        

      </section>




       
    );
}

//export default Footer;