import {firebase, getApps, initializeApp} from "firebase/app";
//import {getAnalytics} from "firebase/analytics";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  console.log("NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY: ", process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY)

  console.log(process.env);

if (!getApps.length)
{
    initializeApp(firebaseConfig);
    if (typeof window !== "undefined") {
        if("measurementId" in firebaseConfig)
        {
            //getAnalytics();
            

        }

    }
}
  

  // Initialize Firebase


 
  //const app = initializeApp(firebaseConfig);
  //const analytics = getAnalytics(app);
  const db = getFirestore(); 
  
  export { db };