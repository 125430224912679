import { memo, useEffect, useState, useContext } from 'react'
import {motion, AnimatePresence } from 'framer-motion'
import NavBtnsHamburger from './nav-btns-hamburger'

const backdrop = {

    visible: {opacity: 1},
    hidden: {opacity: 0}
}

const modal = {

    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "0px",
        opacity: 1,
        transition: {delay: 0.5}
    },
}


//export const App = memo(({auth, showSignup, showModal, setShowModal}) => {
//export const Form = memo((props, useAuth) => {
//export const ModalHamburger = memo(({showModal, setShowModal, modalMessage}) => {
    
export const ModalHamburger = ({showModal, setShowModal, modalMessage}) => {
//export const Modal = ({ showModal, setShowModal }) => {

//  const { showModal} = auth;


    useEffect(() => {
       
        //setShowModal(true);

        //console.log("inside modal.js showModal" + showModal);

        //console.log(auth)



    }, [])



    return (

        
        
        
      
        
        <div>
        

            { showModal &&  (

                <motion.div className="bg-gray-900  text-gray-100 bg-opacity-90  absolute inset-0  z-50  mt-72 
                ml-0
                flex justify-center items-center"  
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden">
                    
                    <motion.div className="bg-black bg-opacity-90  rounded-lg h-[450px] w-[300px]  
                     border border-gray-500 grid  justify-center items-center"
                     variants={modal}
                    >
                        <NavBtnsHamburger setShowModal={setShowModal}/>

                        
                    </motion.div>

                </motion.div>


            )}

</div>
        


    )

}
//);


//export default Modal;